import { brandAssetsPath } from 'components/config/cloud';

export const BrandAssetsData = {
  evoplay: {
    'Horizontal Logo': `${brandAssetsPath}/Evoplay-Logo-Horizontal.zip`,
    'Vertical Logo': `${brandAssetsPath}/Evoplay-Logo-Vertical.zip`,
    All: `${brandAssetsPath}/Evoplay-All-Brand-Assets.zip`,
    Mascot: `${brandAssetsPath}/Evoplay-Mascot-Evo.zip`,
  },
  slotopia: {
    'Horizontal Logo': `${brandAssetsPath}/Slotopia-Logo-Horizontal.zip`,
    'Vertical Logo': `${brandAssetsPath}/Slotopia-Logo-Vertical.zip`,
    All: `${brandAssetsPath}/Slotopia-All-Logos.zip`,
  },
};
