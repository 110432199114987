import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { sidebarSelector } from 'store/slices/sidebar';

import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import { ReactComponent as ShortLogo } from 'images/Logo/Logo.svg';
import { ReactComponent as LogoWithText } from 'images/Logo/LogoWithText.svg';

import styles from './Logo.module.scss';

const Logo = () => {
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hideSidebar]: !isVisibleSidebar,
      })}
      data-testid="logo-root"
    >
      <Link to={findPathPages(PAGE_PATH.HOME)} data-testid="logo-link">
        {isVisibleSidebar ? (
          <LogoWithText data-testid="logo-icon-with-text" />
        ) : (
          <ShortLogo width={33} data-testid="logo-icon-short" />
        )}
      </Link>
    </div>
  );
};

export default Logo;
