import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { numberFormat } from 'utils/number';

import styles from 'components/pages/Promotions/Item/Item.module.scss';

const propTypes = {
  termsAndConditions: PropTypes.string,
  placeAndPrize: PropTypes.shape({}),
  prizesTotal: PropTypes.string,
  placeAndPrizeTotal: PropTypes.number,
  isPrizeDrops: PropTypes.bool,
};

const TermsAndPrize = ({
  termsAndConditions = null,
  placeAndPrize = null,
  placeAndPrizeTotal = null,
  prizesTotal = null,
  isPrizeDrops = false,
}) => (
  <>
    {termsAndConditions && (
      <div className={styles.terms}>
        <div className={styles.title}>Terms and conditions</div>
        <div
          dangerouslySetInnerHTML={{
            __html: termsAndConditions,
          }}
        />
      </div>
    )}
    {placeAndPrize && (
      <div className={styles.prize}>
        <div className={styles.title}>
          {isPrizeDrops ? 'Prizes' : 'Place and prize allocations'}
        </div>
        <div className={styles.prizeContent}>
          <div className={styles.prizeContentTitle}>
            {isPrizeDrops ? 'Quantity' : 'Place'}
          </div>
          <div className={styles.prizeContentTitle}>Prize (EUR)</div>
          {Object.keys(placeAndPrize).map((prize) => (
            <React.Fragment key={prize}>
              <div className={styles.prizeContentOption}>{prize}</div>
              <div className={styles.prizeContentOption}>
                {placeAndPrize[prize]}
              </div>
            </React.Fragment>
          ))}
          <div
            className={classNames(styles.prizeContentFooter, {
              [styles.footerPrize]: prizesTotal,
            })}
          >
            <div className={styles.prizeContentFooterTotal}>
              Total {numberFormat(placeAndPrizeTotal)} EUR
            </div>
            {prizesTotal && (
              <div className={styles.prizeContentFooterPrize}>
                {prizesTotal}
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </>
);

TermsAndPrize.propTypes = propTypes;
export default TermsAndPrize;
