export const config = {
  // local: {
  //   url: 'http://localhost:8080',
  //   realm: 'auth-services',
  //   clientId: 'client-area',
  // },
  local: {
    url: 'https://keycloack.evoent.tech',
    realm: 'auth-services',
    clientId: 'client-area',
  },
  stage: {
    url: 'https://keycloack.evoent.tech',
    realm: 'auth-services',
    clientId: 'client-area',
  },
  prod: {
    url: 'https://auth.evoent.tech',
    realm: 'auth-services',
    clientId: 'client-area',
  },
};
