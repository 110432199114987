import { useCallback, useEffect, useRef, useState } from 'react';

import { triggerPlausibleEvent } from 'utils/plausible';

export const durationProps = {
  from30sTo1m: 'from-30sec-to-1min',
  from1mTo2m: 'from-1min-to-2-min',
  moreThan2m: 'more-than-2-min',
};

export const useWatchDepthTracking = (event) => {
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [sentEvents, setSentEvents] = useState(
    Object.values(durationProps).reduce(
      (acc, value) => ({ ...acc, [value]: false }),
      {},
    ),
  );

  const unmountValuesRef = useRef({});

  const getTimeDiff = useCallback(
    () => (Date.now() - lastUpdateTime) / 1000,
    [lastUpdateTime],
  );

  const startTrackingTime = useCallback(
    () => setLastUpdateTime(Date.now()),
    [],
  );

  const stopTrackingTime = useCallback(() => {
    setPlayedSeconds((prev) => prev + getTimeDiff());
    setLastUpdateTime(null);
  }, [getTimeDiff]);

  const triggerEvent = useCallback(
    (duration) => {
      triggerPlausibleEvent({
        ...event,
        props: { ...event.props, 'video-play': duration },
      });

      setSentEvents((prev) => ({ ...prev, [duration]: true }));
    },
    [event],
  );

  const handleTrackEvent = useCallback(
    (played = playedSeconds) => {
      switch (true) {
        case played >= 30 &&
          played < 60 &&
          !sentEvents[durationProps.from30sTo1m]:
          triggerEvent(durationProps.from30sTo1m);
          break;
        case played >= 60 &&
          played < 120 &&
          !sentEvents[durationProps.from1mTo2m]:
          triggerEvent(durationProps.from1mTo2m);
          break;
        case played >= 120 && !sentEvents[durationProps.moreThan2m]:
          triggerEvent(durationProps.moreThan2m);
          break;
      }
    },
    [playedSeconds, sentEvents, triggerEvent],
  );

  useEffect(() => {
    handleTrackEvent();
  }, [playedSeconds]);

  useEffect(() => {
    unmountValuesRef.current = {
      lastUpdateTime,
      playedSeconds,
      getTimeDiff,
      handleTrackEvent,
    };
  }, [lastUpdateTime, playedSeconds, getTimeDiff, handleTrackEvent]);

  useEffect(() => {
    const handleUnmount = () => {
      const { lastUpdateTime, playedSeconds, getTimeDiff, handleTrackEvent } =
        unmountValuesRef.current;

      lastUpdateTime && handleTrackEvent(playedSeconds + getTimeDiff());
    };

    window.addEventListener('beforeunload', handleUnmount);

    return () => {
      handleUnmount();

      window.removeEventListener('beforeunload', handleUnmount);
    };
  }, []);

  return {
    startTrackingTime,
    stopTrackingTime,
  };
};
