import { ListObjectsCommand } from '@aws-sdk/client-s3';

import { s3Client } from 'config/s3';

export const getS3Items = async (bucketName, prefixes) => {
  if (Array.isArray(prefixes)) {
    const results = await Promise.all(
      prefixes.map((prefix) =>
        s3Client.send(
          new ListObjectsCommand({ Bucket: bucketName, Prefix: prefix }),
        ),
      ),
    );

    return results.flatMap(({ Contents }) => Contents ?? []);
  }

  const { Contents } = await s3Client.send(
    new ListObjectsCommand({ Bucket: bucketName, Prefix: prefixes }),
  );

  return Contents ?? [];
};
