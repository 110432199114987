import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';

import { allGamesSelector, viewType } from 'store/slices/allGames';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as ViewColumn } from 'images/Icons/ViewColumn.svg';
import { ReactComponent as ViewRow } from 'images/Icons/ViewRow.svg';

import styles from './ViewType.module.scss';

const ViewType = () => {
  const dispatch = useDispatch();
  const activeType = useSelector(allGamesSelector.getViewType);
  const handelClick = useCallback(
    (type) => (activeType !== type ? dispatch(viewType(type)) : null),
    [activeType],
  );

  return (
    <div>
      <IconButton onClick={() => handelClick('row')}>
        <ViewRow
          className={classNames(styles.icon, {
            [styles.activeIcon]: activeType === 'row',
          })}
        />
      </IconButton>
      <IconButton onClick={() => handelClick('column')}>
        <ViewColumn
          className={classNames(styles.icon, {
            [styles.activeIcon]: activeType === 'column',
          })}
        />
      </IconButton>
    </div>
  );
};

export default ViewType;
