import React from 'react';
import { useInView } from 'react-intersection-observer';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'components/Layouts/Animation/AnimationLayout.module.scss';

const propTypes = {
  options: PropTypes.shape({
    threshold: PropTypes.number,
    triggerOnce: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  animation: PropTypes.string.isRequired,
};

const AnimationLayout = ({
  options = { threshold: 0, triggerOnce: true },
  children,
  animation,
}) => {
  const [ref, inView] = useInView(options);

  return (
    <div ref={ref} className={classNames({ [styles[animation]]: inView })}>
      {children}
    </div>
  );
};

AnimationLayout.propTypes = propTypes;
export default AnimationLayout;
