import React, { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { findPathPages } from 'utils/pages';

import { getIcon } from 'components/config/cloud';
import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/Promotions/Item/Item.module.scss';

const propTypes = {
  gamesParticipating: PropTypes.arrayOf(
    PropTypes.shape({
      gameCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
};

const GamesParticipating = ({ gamesParticipating, title }) => {
  const gamesList = useMemo(
    () => gamesParticipating.reduce((acc, game) => [...acc, game.name], []),
    [gamesParticipating],
  );

  return (
    <div className={styles.gamesParticipating}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.games}>
        {gamesParticipating.map(({ gameCode }) => (
          <Link
            key={gameCode}
            to={findPathPages(PAGE_PATH.GAME).replace(':gameCode', gameCode)}
          >
            <LazyLoadImage src={getIcon(gameCode)} alt={gameCode} />
          </Link>
        ))}
      </div>
      <div className={styles.gamesList}>{gamesList.join(', ')}</div>
    </div>
  );
};

GamesParticipating.propTypes = propTypes;
export default GamesParticipating;
