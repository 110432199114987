import { useEffect, useState } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { store } from 'store';

import { initKeycloak } from 'services/UserService';

import App from 'components/App/App';

import { PublicPages } from 'components/config/pages';

const AppInit = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initKeycloak(() => setInitialized(true));
  }, []);

  return initialized && <App />;
};

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {Object.entries(PublicPages).map(([key, { path, Element }]) => (
            <Route key={key} path={path} element={<Element />} />
          ))}
          <Route path="*" element={<AppInit />} />
        </Routes>
      </BrowserRouter>
    </Provider>,
  );
};

// TODO: think about rewriting
// eslint-disable-next-line
if (isSupportedBrowser && isSupportedBrowser()) {
  renderApp();
}
