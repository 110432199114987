export const getComingSoon = (promotions) =>
  promotions.filter((item) => {
    const dateStart = item.period[0].split(' ')[0].split('.');
    const hourStart = item.period[0].split(' ')[1].split(':');
    return (
      new Date(
        dateStart[2],
        Number(dateStart[1]) - 1,
        dateStart[0],
        hourStart[0],
        hourStart[1],
      ).getTime() > Date.now()
    );
  });
export const getRunning = (promotions) =>
  promotions.filter((item) => isRunning(item.period));
export const getEnded = (promotions) =>
  promotions
    .filter((item) => {
      const dateEnd = item.period[1].split(' ')[0].split('.');
      const hourEnd = item.period[1].split(' ')[1].split(':');
      return (
        new Date(
          dateEnd[2],
          Number(dateEnd[1]) - 1,
          dateEnd[0],
          hourEnd[0],
          hourEnd[1],
        ).getTime() < Date.now()
      );
    })
    .sort((a, b) => {
      const AdateEnd = a.period[1].split(' ')[0].split('.');
      const AhourEnd = a.period[1].split(' ')[1].split(':');
      const BdateEnd = b.period[1].split(' ')[0].split('.');
      const BhourEnd = b.period[1].split(' ')[1].split(':');

      return (
        new Date(
          BdateEnd[2],
          Number(BdateEnd[1]) - 1,
          BdateEnd[0],
          BhourEnd[0],
          BhourEnd[1],
        ) -
        new Date(
          AdateEnd[2],
          Number(AdateEnd[1]) - 1,
          AdateEnd[0],
          AhourEnd[0],
          AhourEnd[1],
        )
      );
    });
export const getMarkets = (promotions) =>
  promotions.reduce(
    (acc, item) => Array.from(new Set([...acc, ...item.market])),
    [],
  );
export const isRunning = (period) => {
  const dateStart = period[0].split(' ')[0].split('.');
  const hourStart = period[0].split(' ')[1].split(':');
  const dateEnd = period[1].split(' ')[0].split('.');
  const hourEnd = period[1].split(' ')[1].split(':');
  return (
    new Date(
      dateStart[2],
      Number(dateStart[1]) - 1,
      dateStart[0],
      hourStart[0],
      hourStart[1],
    ).getTime() <= Date.now() &&
    new Date(
      dateEnd[2],
      Number(dateEnd[1]) - 1,
      dateEnd[0],
      hourEnd[0],
      hourEnd[1],
    ).getTime() > Date.now()
  );
};

export const filterByMarket = (promotions, market) =>
  promotions.filter((item) => item.market.includes(market));

export const getPackStructure = (materials) => {
  const result = {};
  if (!Array.isArray(materials)) {
    Object.keys(materials).map((pack) => {
      Array.isArray(materials[pack]) &&
        materials[pack].map((item) => {
          result[item] = result[item] ? result[item].concat(pack) : [pack];
        });
    });
  }
  return result;
};
