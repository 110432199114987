import React from 'react';

import Tooltip from 'components/UI/Tooltip/Tooltip';

import { ReactComponent as ComingSoonIcon } from 'images/Icons/ComingSoon.svg';

const ComingSoon = () => (
  <Tooltip title="Coming Soon">
    <ComingSoonIcon />
  </Tooltip>
);

export default ComingSoon;
