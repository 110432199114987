import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from 'components/UI/Checkbox/Checkbox';

import styles from './Accordion.module.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  option: PropTypes.shape({
    title: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

const monthPrepare = (mont) => (mont < 10 ? `0${mont}` : mont);

const Accordion = ({ label, value, option, defaultOpen, handleSelect }) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleCheck = useCallback(
    (event) => {
      const year = `${option.title}`;
      let data;
      if (event.target.checked) {
        data = new Set(value.filter((item) => item.indexOf(year) === -1));
        data.add(year);
      } else {
        data = new Set(value);
        data.delete(year);
      }
      handleSelect(label, Array.from(data));
    },
    [value],
  );

  const handleClick = useCallback(
    (month) => {
      const data = new Set(value);
      if (data.has(`${monthPrepare(month)}.${option.title}`)) {
        data.delete(`${monthPrepare(month)}.${option.title}`);
      } else {
        data.delete(`${option.title}`);
        data.add(`${monthPrepare(month)}.${option.title}`);
      }
      handleSelect(label, Array.from(data));
    },
    [value],
  );

  const handleClickArrow = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <>
      <div className={styles.yearWrapper}>
        <div
          className={classNames(styles.checkbox, {
            [styles.checked]: value.includes(`${option.title}`),
          })}
        >
          <Checkbox
            checked={value.includes(`${option.title}`)}
            onChange={handleCheck}
          />
          {option.title}
        </div>
        <div className={styles.arrowWrapper} onClick={handleClickArrow}>
          <div
            className={classNames(styles.arrow, {
              [styles.arrowUp]: open,
              [styles.arrowDown]: !open,
            })}
          ></div>
        </div>
      </div>
      <div
        className={classNames(styles.monthsWrapper, {
          [styles.monthsWrapperOpen]: open,
        })}
      >
        {option.items.map((month, index) => (
          <div
            key={`${index + 1}.${option.title}`}
            className={classNames(styles.month, {
              [styles.selected]:
                value.includes(`${option.title}`) ||
                value.includes(`${monthPrepare(index + 1)}.${option.title}`),
            })}
            onClick={() => handleClick(index + 1)}
          >
            {month}
          </div>
        ))}
      </div>
    </>
  );
};

Accordion.propTypes = propTypes;
export default Accordion;
