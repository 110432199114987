import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Switch as MuiSwitch, styled } from '@mui/material';

import styles from './Switch.module.scss';

const propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
  onChange: PropTypes.func,
};

const AntSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 23,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(21px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#FAFAFA',
        border: '1px solid #0099FA',
      },
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#0099FA',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: '#FAFAFA',
    border: '1px solid #EBEBEC',
    boxSizing: 'border-box',
  },
  '&:hover .MuiSwitch-track': {
    borderColor: '#0099FA',
  },
}));

const Switch = ({ from, to, onChange = () => {}, ...props }) => {
  const [checked, setChecked] = useState(false);
  const onHandleChange = useCallback((el) => {
    setChecked(el.target.checked);
    onChange(el.target.checked);
  }, []);

  return (
    <div className={styles.wrapper}>
      {from && (
        <div
          className={classNames(styles.label, { [styles.active]: !checked })}
        >
          {from}
        </div>
      )}
      <AntSwitch
        {...props}
        inputProps={{ name: 'Switch' }}
        onChange={onHandleChange}
      />
      {to && (
        <div className={classNames(styles.label, { [styles.active]: checked })}>
          {to}
        </div>
      )}
    </div>
  );
};

Switch.propTypes = propTypes;
export default Switch;
