import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { userSelector } from 'store/slices/user';

export const HOTJAR_ID = 3400411;

const useHotjar = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_VERSION === 'prod' && !hotjar.initialized()) {
      hotjar.initialize({
        id: HOTJAR_ID,
        sv: 6,
      });
    }
  }, []);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(location.pathname);
    }
  }, [location]);
};

export const useHotjarSurvey = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCustomer = useSelector(userSelector.isCustomer);

  useEffect(() => {
    if (isCustomer) {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.get('show-survey') !== 'true') {
        searchParams.set('show-survey', 'true');
        navigate({ search: searchParams.toString() }, { replace: true });
      }
    }
  }, [isCustomer, location.search]);
};

export default useHotjar;
