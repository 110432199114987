import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetTournamentsQuery } from 'store/api/tournamentService/recipe.api';
import { userSelector } from 'store/slices/user';

import { IMAGES } from 'components/config/tournaments';

const useGetTournaments = () => {
  const { id } = useSelector(userSelector.getInfo) ?? {};

  const { data } = useGetTournamentsQuery({ user_id: id }, { skip: !id });

  return useMemo(
    () =>
      data?.tournamentEvents.length
        ? [...data.tournamentEvents]
            .sort(
              (a, b) =>
                new Date(b.event.activeStatusStartTime) -
                new Date(a.event.activeStatusStartTime),
            )
            .map((item) => ({ ...item, event: { ...item.event, ...IMAGES } }))
        : undefined,
    [data],
  );
};

export default useGetTournaments;
