import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { stripHtml } from 'utils/formatters';
import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from './Main.module.scss';

const propTypes = {
  news: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
  }).isRequired,
};

const Main = ({ news }) => (
  <Link
    className={styles.wrapper}
    to={findPathPages(PAGE_PATH.NEWS_ITEM)
      .replace(':category', news.category)
      .replace(':item', news.id)}
  >
    <img src={news.img} alt="Main News" />
    <div className={styles.content}>
      <div className={styles.title}>{stripHtml(news.shortDescription)}</div>
      <div className={styles.date}>{news.date}</div>
    </div>
  </Link>
);

Main.propTypes = propTypes;
export default Main;
