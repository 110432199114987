import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

export const s3Client = new S3Client({
  region: 'eu-central-1',
  credentials: fromCognitoIdentityPool({
    clientConfig: { region: 'eu-central-1' },
    identityPoolId: 'eu-central-1:2b8a4075-3053-4010-94b5-130c6ee172bd',
  }),
});
