import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import useAccessView from 'hooks/tournaments/accessView';
import useGetTournaments from 'hooks/tournaments/tournaments';

import { onboardingSelector } from 'store/slices/onboarding';
import {
  close,
  open,
  tournamentPopupSelector,
} from 'store/slices/tournamentPopup';

import { differenceDateTime } from 'utils/date';
import { findPathPages } from 'utils/pages';
import { getTournamentsByStatus } from 'utils/tournaments';

import Banner from 'components/TournamentPopup/Banner/Banner';
import Button from 'components/UI/Button/Button';
import Dialog from 'components/UI/Dialog/Dialog';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES } from 'components/config/tournaments';

import styles from 'components/TournamentPopup/TournamentPopup.module.scss';

const TournamentPopup = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [tournament, setTournament] = useState(null);
  const tournamentEvents = useGetTournaments();
  const hasAccessView = useAccessView();

  const isVisible = useSelector(tournamentPopupSelector.getVisible);
  const isVisibleOnboarding = useSelector(onboardingSelector.getVisible);

  const handleClose = useCallback(() => {
    dispatch(close());
    setTournament(null);
  }, []);

  useEffect(() => {
    if (
      location?.pathname.indexOf('tournaments') === -1 &&
      !isVisibleOnboarding
    ) {
      const running = tournamentEvents
        ? getTournamentsByStatus(tournamentEvents, STATUSES.running)
        : [];
      const comingSoon = tournamentEvents
        ? getTournamentsByStatus(tournamentEvents, STATUSES.comingSoon)
        : [];

      if (running.length) {
        setTournament(running[0]);
      } else if (comingSoon.length) {
        setTournament(comingSoon[0]);
      }
    }
  }, [tournamentEvents]);

  useEffect(() => {
    if (tournament?.event) {
      if (tournament.event.eventStatus === STATUSES.running) {
        dispatch(open(tournament.event.eventId));
      } else {
        const { date } = differenceDateTime(
          new Date(tournament.event.activeStatusStartTime),
        );
        if (date <= 7) {
          dispatch(open(tournament.event.eventId));
        }
      }
    }
  }, [tournament]);

  if (!tournament?.event || !isVisible || !hasAccessView) {
    return null;
  }

  return (
    <Dialog handleClose={handleClose} open options={{ hideScroll: true }}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Exciting News:
          <br />
          Introducing B2B Tournaments!
        </div>
        <div className={styles.subTitle}>
          We&#39;re thrilled to announce the launch of our exciting tournaments,
          where you can showcase your skills, compete against others, and win
          fabulous prizes.
        </div>
        <Banner item={tournament.event} />
        <div className={styles.moreBtn}>
          <Button
            component={Link}
            to={findPathPages(PAGE_PATH.TOURNAMENTS_ITEM).replace(
              ':item',
              tournament.event.eventId,
            )}
            onClick={handleClose}
          >
            Learn more
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default TournamentPopup;
