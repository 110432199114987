import React, { forwardRef } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as SearchIcon } from 'images/Icons/Search.svg';

import styles from './SearchInput.module.scss';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  customStyle: PropTypes.string,
  placeholder: PropTypes.string,
};

const SearchInput = forwardRef(function SearchInput(
  { handleChange, customStyle = null, placeholder = 'Search' },
  ref,
) {
  return (
    <div className={classNames(styles.wrapper, customStyle)}>
      <input
        type="text"
        name="autocomplete"
        placeholder={placeholder}
        onChange={handleChange}
        ref={ref}
        autoComplete="off"
      />
      <SearchIcon />
    </div>
  );
});

SearchInput.propTypes = propTypes;
export default SearchInput;
