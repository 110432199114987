import { updateToken } from 'services/UserService';

export const baseQueryWithRauth = async (
  args,
  api,
  extraOptions,
  baseQuery,
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    await updateToken();
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};
