import React from 'react';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/Sidebar/ContentLinks/ContentLinks.module.scss';

const links = [
  { title: 'Privacy Notice', path: 'privacy' },
  { title: 'Website Terms', path: 'terms' },
  { title: 'Cookie Notice', path: 'cookie' },
];

const ContentLinks = () => (
  <div className={styles.links}>
    {links.map(({ title, path }) => (
      <NavLink
        key={path}
        to={findPathPages(PAGE_PATH.CONTENTS).replace(':page', path)}
        className={({ isActive }) =>
          classNames(styles.link, { [styles.active]: isActive })
        }
      >
        {title}
      </NavLink>
    ))}
  </div>
);

export default ContentLinks;
