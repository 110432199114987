export const categories = {
  news: {
    title: 'News',
    postType: 'NEWS',
  },
  updates: {
    title: 'Tech and Product Updates',
    postType: 'UPDATE',
  },
};
