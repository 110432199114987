import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { downloadProcessSelector } from 'store/slices/downloadProcess';

import Loader from 'components/Loader/Loader';

import styles from './DownloadProcess.module.scss';

const DownloadProcess = () => {
  const [expand, setExpand] = useState(true);

  const opened = useSelector(downloadProcessSelector.getModalOpened);
  const items = useSelector(downloadProcessSelector.getItems);
  const zipGenerating = useSelector(downloadProcessSelector.getZipGenerating);

  const handleExpand = useCallback(
    () => setExpand((prevState) => !prevState),
    [],
  );

  return (
    opened && (
      <div className={styles.wrapper} data-testid="download-process-root">
        <div
          className={styles.header}
          onClick={handleExpand}
          data-testid="download-process-header"
        >
          <div>Loading</div>
          <Loader type="process" />
          <div className={styles.arrowWrapper}>
            <div
              className={classNames(styles.arrow, {
                [styles.arrowUp]: !expand,
                [styles.arrowDown]: expand,
              })}
            />
          </div>
        </div>
        {expand && (
          <div
            className={styles.content}
            data-testid="download-process-content"
          >
            {zipGenerating ? (
              <div
                className={styles.zipGenerating}
                data-testid="download-process-zip-generating"
              >
                Adding files to ZIP...
              </div>
            ) : (
              Object.entries(items).map(([key, { percent }]) => (
                <div key={key} className={styles.item}>
                  <div
                    className={styles.fileName}
                    dangerouslySetInnerHTML={{ __html: key }}
                  />
                  <div className={styles.progressBar}>
                    <div
                      style={{ width: `${percent}%` }}
                      data-testid={`download-process-item-progress-${percent}`}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    )
  );
};

export default memo(DownloadProcess);
