import StorageCollection from 'utils/storage/StorageCollection';

const StorageKey = 'tournamentPopupState';
const StorageData = {
  countShow: 1,
  tournament: '',
};
const storage = new StorageCollection(StorageKey, StorageData);
export const getStorageData = () => storage.getAll();
export const setStorageData = (data) => storage.add(data);
