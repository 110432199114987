import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  useGetGameJurisdictionsQuery,
  useGetGameLanguagesQuery,
  useGetGameMarkersQuery,
  useGetGamePromoToolsQuery,
  useGetGameTypesQuery,
  useGetGameVolatilitiesQuery,
} from 'store/api/configService/enumsContoller';
import { allGamesSelector } from 'store/slices/allGames';

import { generateFiltersData } from 'components/config/filters';

export const useGameFilters = () => {
  const filters = useSelector(allGamesSelector.getFilters);

  const gameTypes = useGetGameTypesQuery();
  const markers = useGetGameMarkersQuery();
  const jurisdictions = useGetGameJurisdictionsQuery();
  const languages = useGetGameLanguagesQuery();
  const promoTools = useGetGamePromoToolsQuery();
  const volatilities = useGetGameVolatilitiesQuery();

  const data = useMemo(
    () =>
      generateFiltersData({
        gameTypes,
        markers,
        jurisdictions,
        languages,
        promoTools,
        volatilities,
      }),
    [gameTypes, markers, jurisdictions, languages, promoTools, volatilities],
  );

  const filterValues = useMemo(() => {
    if (!Object.keys(filters).length) return filters;

    return Object.entries(filters).reduce((acc, [key, values]) => {
      const { options } = data.find(({ label }) => label === key) ?? {};

      return {
        ...acc,
        [key]: options
          ? values.map(
              (value) =>
                options.find(({ label }) => label === value)?.value ?? value,
            )
          : values,
      };
    }, {});
  }, [filters, data]);

  return {
    data,
    filters: filterValues,
  };
};
