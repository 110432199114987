import React from 'react';

import PropTypes from 'prop-types';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.number.isRequired,
  checkbox: PropTypes.bool,
  widthDropdown: PropTypes.number,
};

const Theme = ({ children, width, widthDropdown, checkbox = false }) => {
  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            width: width,
            ['&:hover .MuiFormLabel-root ']: {
              color: '#090A0B',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            fontSize: 14,
            lineHeight: '22px',
            height: 40,
            ['& fieldset']: {
              borderRadius: '12px',
              borderColor: '#EAEBEE',
              backgroundColor: '#FFF',
            },
            ['&.Mui-focused']: {
              ['& fieldset']: {
                borderColor: '#0099FA !important',
                borderWidth: '1px !important',
              },
            },
            ['&:hover']: {
              ['& fieldset']: {
                borderColor: '#888B8D !important',
              },
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          filled: {
            transform: 'translate(14px, -7px) scale(0.75) !important',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            transform: 'translate(14px, 9px) scale(1)',
            fontFamily: 'inherit',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 400,
            color: '#888B8D',
            ['&.Mui-focused']: {
              transform: 'translate(14px, -7px) scale(0.75);',
              color: '#0099FA',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: '0 12px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            marginTop: 5,
            borderRadius: '12px',
            padding: '8px 12px',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            width:
              widthDropdown && widthDropdown > width
                ? widthDropdown
                : width + 50,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            maxHeight: 500,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            fontSize: 14,
            lineHeight: '18px',
            padding: 0,
            paddingRight: 5,
            borderRadius: '12px',
            color: '#888B8D',
            ['&:hover']: {
              backgroundColor: '#FAFAFA',
            },
            ['&.Mui-focusVisible']: {
              backgroundColor: '#FAFAFA',
            },
            ['&.Mui-selected']: {
              color: '#090A0B',
              backgroundColor: '#FFFFFF',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingRight: 10,
            paddingLeft: checkbox ? 0 : 10,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            padding: 0,
            fontFamily: 'inherit',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            zIndex: 1,
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = propTypes;
export default Theme;
