import React from 'react';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import useGetTournaments from 'hooks/tournaments/tournaments';

import { findPathPages } from 'utils/pages';
import { getTournamentsByStatus } from 'utils/tournaments';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import NotFound from 'components/pages/NotFound/NotFound';
import Card from 'components/pages/Tournaments/Card/Card';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES, getCategory } from 'components/config/tournaments';

import styles from 'components/pages/Tournaments/Category/Category.module.scss';
import stylesLayout from 'components/pages/Tournaments/Tournaments.module.scss';

export const Category = () => {
  const { category } = useParams();
  const categoryConfig = getCategory(category);
  const tournamentEvents = useGetTournaments();

  if (!categoryConfig) {
    return <NotFound />;
  }

  const items = tournamentEvents
    ? getTournamentsByStatus(tournamentEvents, STATUSES[category])
    : [];

  if (!items.length) {
    return null;
  }

  return (
    <div className={stylesLayout.wrapper}>
      <div className={styles.back}>
        <ArrowCircleLink
          path={findPathPages(PAGE_PATH.TOURNAMENTS)}
          title="Go to all tournaments"
          direction="left"
        />
      </div>
      <div className={stylesLayout[category]}>
        <div className={stylesLayout.title}>
          <div>
            <span>{categoryConfig.title}</span>
          </div>
        </div>
        <div className={classNames(styles.content, stylesLayout.content)}>
          {items.map((item, index) => (
            <Card key={index} item={item.event} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
