import { Component, useCallback } from 'react';

import PropTypes from 'prop-types';

import Button from 'components/UI/Button/Button';

import styles from './ErrorBoundary.module.scss';

const DefaultFallback = () => {
  const handleReload = useCallback(() => window.location.reload(), []);

  return (
    <div className={styles.container}>
      <div className={styles.title} data-testid="err-boundary-fallback-title">
        Oops! Something went wrong.
      </div>
      <div
        className={styles.description}
        data-testid="err-boundary-fallback-description"
      >
        We’re sorry for the inconvenience. Please try refreshing the page, or
        contact support if the problem persists.
      </div>
      <Button onClick={handleReload} data-testid="err-boundary-fallback-button">
        Refresh page
      </Button>
    </div>
  );
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    return this.state.error
      ? (this.props.fallback?.() ?? <DefaultFallback />)
      : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.func,
};

export default ErrorBoundary;
