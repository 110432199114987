import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import Button from 'components/UI/Button/Button';

import styles from './Dialog.module.scss';

const theme = ({ type, hideScroll, paperStyles }) =>
  createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            maxWidth: type === 'opacity' ? 1180 : 940,
            backgroundColor: type === 'opacity' ? 'rgba(255, 255, 255, 0)' : '',
            boxShadow: type === 'opacity' ? 'none' : '',
            ...paperStyles,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            display: 'flex',
            justifyContent: 'right',
            padding: '15px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0 40px',
            overflowX: 'hidden',
            overflowY: hideScroll ? 'hidden' : 'auto',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '20px 0 40px',
            justifyContent: 'center',
            ['& >:not(:first-of-type)']: {
              marginLeft: 20,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: 180,
          },
        },
      },
    },
  });

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      props: PropTypes.shape({}),
    }),
  ),
  options: PropTypes.shape({
    type: PropTypes.string,
    hideScroll: PropTypes.bool,
    paperStyles: PropTypes.object,
  }),
  withCloseBtn: PropTypes.bool,
};

const Dialog = ({
  open,
  handleClose,
  children,
  actions,
  options = {},
  withCloseBtn = true,
  ...props
}) => (
  <ThemeProvider theme={theme(options)}>
    <MuiDialog open={open} onClose={handleClose} {...props}>
      <DialogTitle>
        {withCloseBtn && (
          <div
            className={classNames(styles.close, {
              [styles.opacity]: options?.type === 'opacity',
            })}
            onClick={handleClose}
            data-testid="dialog-close"
          />
        )}
      </DialogTitle>
      <DialogContent>
        {children}
        {!actions && <div className={styles.withoutActions}></div>}
      </DialogContent>
      {actions && (
        <DialogActions>
          {actions.map((el) => (
            <Button key={el.title} onClick={el.action} {...el.props}>
              {el.title}
            </Button>
          ))}
        </DialogActions>
      )}
    </MuiDialog>
  </ThemeProvider>
);

Dialog.propTypes = propTypes;
export default Dialog;
