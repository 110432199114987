import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowCircle } from 'images/Icons/ArrowCircle.svg';

import styles from 'components/ArrowCircleLink/ArrowCircleLink.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  style: PropTypes.string,
  direction: PropTypes.oneOf(['right', 'left']),
};

const ArrowCircleLink = ({
  title,
  path,
  style = null,
  direction = 'right',
}) => (
  <Link to={path} className={classNames(styles.link, styles[direction], style)}>
    {title}
    <ArrowCircle className={styles.icon} />
  </Link>
);

ArrowCircleLink.propTypes = propTypes;
export default ArrowCircleLink;
