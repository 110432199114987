import { ReactComponent as FireIcon } from 'images/Icons/HotOffer.svg';

const map = {
  'New Game': {
    color: '#8ACB00',
  },
  'EVOPLAY’s Choice': {
    color: '#0099FA',
  },
  'TOP Asia': {
    color: '#FF4321',
    icon: (props) => <FireIcon {...props} />,
  },
  'TOP Europe': {
    color: '#F0338C',
    icon: (props) => <FireIcon {...props} />,
  },
  'TOP Asia & Europe': {
    color: '#0069FF',
    icon: (props) => <FireIcon {...props} />,
  },
  'Unique Graphics': {
    color: '#FDB10E',
  },
  'Special Terms': {
    color: '#A33EE8',
  },
  'Next release': {
    color: '#0099FA',
  },
};

export const getType = (type) => (map[type] ? map[type] : map['New Game']);
