import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRauth } from 'store/api/queryWithReAuth';

import { getToken } from 'services/UserService';

import { apiConfig as defaultApiConfig } from 'config/api';

const createBaseQuery = (apiConfig = defaultApiConfig) =>
  fetchBaseQuery({
    baseUrl: apiConfig[process.env.REACT_APP_VERSION],
    prepareHeaders: (headers) => {
      const accessToken = getToken();
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      headers.set('Content-Type', 'application/json');
      return headers;
    },
  });

export const createBaseApi = ({ apiConfig, ...params }) =>
  createApi({
    baseQuery: (...props) =>
      baseQueryWithRauth(...props, createBaseQuery(apiConfig)),
    endpoints: () => ({}),
    ...params,
  });
