import React from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useCountdown from 'hooks/countdown';
import PropTypes from 'prop-types';

import { viewModeSelector } from 'store/slices/viewMode';

import { formatDateTime } from 'utils/date';
import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import Button from 'components/UI/Button/Button';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES, getCategory } from 'components/config/tournaments';

import { ReactComponent as Sandglass } from 'images/Icons/Sandglass.svg';

import styles from 'components/pages/Tournaments/Banner/Banner.module.scss';
import stylesLayout from 'components/pages/Tournaments/Tournaments.module.scss';

const propTypes = {
  item: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
    eventStatus: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    mediumBannerUrl: PropTypes.string.isRequired,
    largeBannerUrl: PropTypes.string.isRequired,
    activeStatusStartTime: PropTypes.string.isRequired,
    activeStatusFinishTime: PropTypes.string.isRequired,
    eventPrizePool: PropTypes.string.isRequired,
    minimalBet: PropTypes.string.isRequired,
    eventCurrency: PropTypes.string.isRequired,
    numberOfRewardRanks: PropTypes.number.isRequired,
    rankingDescription: PropTypes.string.isRequired,
  }).isRequired,
  category: PropTypes.string,
  results: PropTypes.shape({
    score: PropTypes.number,
    position: PropTypes.number,
    prize: PropTypes.string,
  }),
};

const Banner = ({ item, category, results }) => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const isSimpleMode = viewMode === VIEW_MODE.SIMPLE;

  const timeLeft = useCountdown(
    item.eventStatus === STATUSES.running
      ? new Date(item.activeStatusFinishTime)
      : new Date(item.activeStatusStartTime),
  );

  const categoryConfig = getCategory(category);

  return (
    <div className={styles.wrapper}>
      <ReactPlayer
        url={isSimpleMode ? item.mediumBannerUrl : item.largeBannerUrl}
        playing={true}
        controls={false}
        loop={true}
        muted={true}
        className={styles.background}
      />
      <div className={styles.gradient}>
        <div className={styles.content}>
          {categoryConfig && (
            <div className={styles.tag}>
              <div
                className={styles.tagContent}
                style={{ color: categoryConfig.color }}
              >
                {categoryConfig.title}
              </div>
            </div>
          )}
          <div className={styles.title}>{item.eventName}</div>
          <div className={styles.period}>
            {formatDateTime(new Date(item.activeStatusStartTime))}
            {' - '}
            {formatDateTime(new Date(item.activeStatusFinishTime))}
          </div>
          <div className={styles.info}>
            {category !== 'ended' && (
              <div className={stylesLayout.chip}>
                <span className={stylesLayout.chipLabel}>
                  <Sandglass />{' '}
                  {item.eventStatus === STATUSES.running
                    ? 'Ends in'
                    : 'Starts in'}
                </span>
                <span className={stylesLayout.chipValue}>
                  {timeLeft
                    ? `${timeLeft.days}d : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`
                    : '-'}
                </span>
              </div>
            )}
            {results?.prize ? (
              <div className={stylesLayout.chip}>
                <span className={stylesLayout.chipLabel}>Prize</span>
                <span className={stylesLayout.chipValue}>{results?.prize}</span>
              </div>
            ) : (
              <div className={stylesLayout.chip}>
                <span className={stylesLayout.chipLabel}>Prize pool</span>
                <span className={stylesLayout.chipValue}>
                  {item.eventPrizePool}
                </span>
              </div>
            )}
            {category === 'ended' && (
              <div className={stylesLayout.chip}>
                <span className={stylesLayout.chipLabel}>Position</span>
                <span className={stylesLayout.chipValue}>
                  {results?.position ? `#${results.position}` : '-'}
                </span>
              </div>
            )}
          </div>
          {!results && (
            <Button
              className={styles.moreBtn}
              component={Link}
              to={findPathPages(PAGE_PATH.TOURNAMENTS_ITEM).replace(
                ':item',
                item.eventId,
              )}
            >
              Learn more
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = propTypes;
export default Banner;
