import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';

import {
  downloadMaterialsSelector,
  setCategory,
  setItems,
  setPack,
} from 'store/slices/downloadMaterials';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import Base from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Base';
import Currency from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Currency';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import SearchInput from 'components/UI/SearchInput/SearchInput';

import { prepareMaterials } from 'components/config/games';

import stylesDialog from 'components/DownloadMaterials/Dialog/Dialog.module.scss';
import styles from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content.module.scss';

const theme = createTheme({
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '9px 5px',
          '& span:after': {
            left: 9.5,
          },
        },
      },
    },
  },
});

const propTypes = {
  materials: PropTypes.shape({}).isRequired,
  structure: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]).isRequired,
  category: PropTypes.string.isRequired,
};

const Content = ({ materials, structure, category }) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(
    structure[Object.keys(structure).find((el) => !!structure[el].open)]
      ?.category || '',
  );
  const [search, setSearch] = useState('');
  const downloadFilters = useSelector(downloadMaterialsSelector.getDownload);

  const handleClickCategory = useCallback(
    (row) => dispatch(setCategory({ category, row })),
    [],
  );
  const handleClickPack = useCallback(
    (row, pack) => dispatch(setPack({ category, row, pack })),
    [],
  );
  const handleClickEl = useCallback(
    (row, pack, el) => dispatch(setItems({ category, row, pack, el })),
    [],
  );
  const handleClickExpand = useCallback((type) => {
    setExpand((prevState) => (prevState !== type ? type : ''));
  }, []);
  const isCheckedRow = useCallback(
    (row) => Boolean(downloadFilters.category[category]?.includes(row)),
    [downloadFilters],
  );
  const isCheckedPack = useCallback(
    (row, pack) =>
      Boolean(
        downloadFilters.pack[category] &&
          downloadFilters.pack[category][row] &&
          downloadFilters.pack[category][row].includes(pack),
      ),
    [downloadFilters],
  );
  const handleAutocomplete = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      setSearch(event.target.value.toLowerCase().trim());
    }, 500),
    [],
  );

  const isCheckedWholeCategory = useMemo(
    () => downloadFilters.wholeCategory.includes(category),
    [downloadFilters],
  );
  const materialsData = useMemo(
    () =>
      Object.keys(materials).length ? materials : prepareMaterials(structure),
    [materials, structure],
  );

  if (Array.isArray(materialsData)) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div
            className={classNames(
              styles.content,
              styles.contentGap,
              styles.contentIcons6,
            )}
          >
            {materialsData.map((item) => (
              <div className={styles.checkbox} key={item}>
                <div
                  className={classNames(
                    styles.checkboxArea,
                    styles.checkboxAreaCurrency,
                  )}
                  onClick={() => handleClickCategory(item)}
                >
                  <Checkbox
                    checked={isCheckedWholeCategory || isCheckedRow(item)}
                  />
                  <span className={styles.checkboxCurrency}>{item}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {Object.keys(structure).map((row) => {
        if (!materialsData[row]) {
          return null;
        }

        return (
          <div className={styles.row} key={row}>
            <div className={styles.header}>
              <div className={styles.title}>
                <Checkbox
                  checked={isCheckedWholeCategory || isCheckedRow(row)}
                  onClick={() => handleClickCategory(row)}
                />
                <span>{structure[row].category}</span>
              </div>
              <div className={styles.allPack}>
                {structure[row].expand && (
                  <>
                    {structure[row].isAutocomplete && expand === row && (
                      <SearchInput handleChange={handleAutocomplete} />
                    )}
                    {Object.keys(materialsData[row]).length > 1 &&
                      Object.keys(materialsData[row]).map((pack) => {
                        if (typeof materialsData[row][pack] !== 'object') {
                          return null;
                        }
                        return (
                          <div
                            className={styles.tag}
                            key={`${row}${pack}`}
                            onClick={() => {
                              if (
                                downloadFilters.pack[category] &&
                                downloadFilters.pack[category][row] &&
                                !downloadFilters.pack[category][row].includes(
                                  pack,
                                ) &&
                                Object.keys(materialsData[row]).length - 1 ===
                                  downloadFilters.pack[category][row].length
                              ) {
                                handleClickCategory(row);
                              } else {
                                handleClickPack(row, pack);
                              }
                            }}
                          >
                            <Checkbox
                              checked={
                                isCheckedWholeCategory ||
                                isCheckedRow(row) ||
                                isCheckedPack(row, pack)
                              }
                            />
                            <span className={styles.label}>All {pack}</span>
                          </div>
                        );
                      })}
                    <div
                      className={classNames(stylesDialog.expand, {
                        [stylesDialog.expandActive]: expand === row,
                      })}
                      onClick={() => handleClickExpand(row)}
                    >
                      <span>{structure[row].expand}</span>
                      <div className={stylesDialog.arrowWrapper}>
                        <div
                          className={classNames(stylesDialog.arrow, {
                            [stylesDialog.arrowUp]: expand === row,
                            [stylesDialog.arrowDown]: expand !== row,
                          })}
                        ></div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {expand === row && (
              <ThemeProvider theme={theme}>
                <div
                  className={classNames(
                    styles.content,
                    styles[`contentIcons${structure[row].countColumns}`],
                  )}
                >
                  {structure[row].component &&
                    structure[row].component === 'Currency' && (
                      <Currency
                        {...structure[row]}
                        search={search}
                        row={row}
                        handleClickEl={handleClickPack}
                        isCheckedWholeCategory={isCheckedWholeCategory}
                        isCheckedRow={isCheckedRow}
                        isCheckedEl={isCheckedPack}
                      />
                    )}
                  {!structure[row].component && (
                    <Base
                      materials={materialsData[row]}
                      downloadFilters={downloadFilters.items[category]}
                      row={row}
                      handleClickEl={handleClickEl}
                      isCheckedWholeCategory={isCheckedWholeCategory}
                      isCheckedPack={isCheckedPack}
                      isCheckedRow={isCheckedRow}
                    />
                  )}
                </div>
              </ThemeProvider>
            )}
          </div>
        );
      })}
    </div>
  );
};

Content.propTypes = propTypes;
export default Content;
