export const paperElement = {
  '&:after': {
    content: '""',
    width: 28,
    height: 28,
    position: 'absolute',
    left: -14,
    top: 30,
    backgroundColor: 'white',
    transform: 'rotate(45deg)',
  },
  overflowY: 'visible',
};

export const getPositionMenu = (menu) => {
  const el = document.querySelector(`[data-id="sidebar-${menu}"]`);

  if (!el) return null;

  document.querySelectorAll('[data-element="sidebar-menu"]').forEach((menu) => {
    menu.style.zIndex = 0;
    menu.style.position = 'static';
  });

  el.style.zIndex = 1301;
  el.style.position = 'relative';

  return {
    PaperProps: {
      sx: {
        position: 'absolute',
        top: `${el.getBoundingClientRect().top - 50}px`,
        left: `${el.getBoundingClientRect().width}px`,
        ...paperElement,
      },
    },
  };
};
