import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { useGameFilters } from 'hooks/gameFilters';

import { useGetGamesListQuery } from 'store/api/configService/gameController';
import { allGamesSelector, selectItem } from 'store/slices/allGames';

import { searchGames } from 'utils/games';

import Card from 'components/GameItem/Card/Card';
import FullInfo from 'components/GameItem/FullInfo/FullInfo';
import Loader from 'components/Loader/Loader';

import { ReactComponent as EmptyFiltersIcon } from 'images/Icons/EmptyFilters.svg';

import styles from './Content.module.scss';

const Content = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const viewType = useSelector(allGamesSelector.getViewType);
  const search = useSelector(allGamesSelector.getSearch);
  const sortBy = useSelector(allGamesSelector.getSortBy);

  const { filters } = useGameFilters();

  const { data, isFetching } = useGetGamesListQuery({
    sort: sortBy,
    filters,
    providers: provider,
  });

  const searchedGames = useMemo(
    () => searchGames(data?.content, search),
    [data?.content, search],
  );

  const GameComponent = useMemo(
    () => (viewType === 'row' ? FullInfo : Card),
    [viewType],
  );

  const handleClickCheckbox = useCallback(
    (itemId) => dispatch(selectItem(itemId)),
    [],
  );

  if (isFetching) return <Loader />;

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.card]: viewType === 'column' && searchedGames?.length,
        [styles.content]: !searchedGames?.length,
      })}
    >
      {!searchedGames?.length ? (
        <div className={styles.empty}>
          <EmptyFiltersIcon />
          <div>
            <div className={styles.title}>
              We couldn&apos;t find any results for the chosen filters.
            </div>
            <div>Please try changing your filter parameters.</div>
          </div>
        </div>
      ) : (
        searchedGames.map((item) => (
          <GameComponent
            key={item.gameCode}
            item={item}
            checkbox
            onClick={handleClickCheckbox}
          />
        ))
      )}
    </div>
  );
};

export default memo(Content);
