const isObjects = (pair) =>
  pair.every((item) => typeof item === 'object' && !Array.isArray(item));

const isArrays = (pair) => pair.every((item) => Array.isArray(item));

export const mergeObjects = (obj1, obj2) =>
  Object.entries(obj2).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: acc[key]
        ? isObjects([acc[key], value])
          ? mergeObjects(acc[key], value)
          : isArrays([acc[key], value])
            ? [...new Set([...acc[key], ...value])]
            : value
        : value,
    }),
    obj1,
  );
