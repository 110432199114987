import React from 'react';

import PropTypes from 'prop-types';

import styles from './GameDemo.module.scss';

const propTypes = {
  src: PropTypes.string.isRequired,
};

const GameDemo = ({ src }) => (
  <div className={styles.wrapper}>
    <iframe
      width="100%"
      height="100%"
      src={src}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Game Demo"
    />
  </div>
);

GameDemo.propTypes = propTypes;
export default GameDemo;
