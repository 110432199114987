import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { useGetRoadmapQuery } from 'store/api/configService/gameController';
import { roadmapSelector, toggleVisible } from 'store/slices/roadmap';

import { getMonthName } from 'utils/date';
import { triggerPlausibleEvent } from 'utils/plausible';

import IconButton from '@mui/material/IconButton';

import Loader from 'components/Loader/Loader';
import Button from 'components/UI/Button/Button';
import Calendar from 'components/UI/Calendar/Event/Calendar';
import Item from 'components/pages/Roadmap/Item/Item';

import { plausibleEvents } from 'components/config/plausibleEvents';
import { pdfPath, xlsxPath } from 'components/config/roadmap';

import { ReactComponent as ArrowCircleIcon } from 'images/Icons/ArrowCircle.svg';
import { ReactComponent as CalendarIcon } from 'images/Icons/Calendar.svg';
import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from './Roadmap.module.scss';

const Roadmap = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const [currentDate, setCurrentDate] = useState(new Date());

  const isVisible = useSelector(roadmapSelector.getVisible);

  const prevMonth = useMemo(
    () => new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    [currentDate],
  );

  const nextMonth = useMemo(
    () =>
      currentDate.getMonth() === 11
        ? new Date(currentDate.getFullYear() + 1, 0, 1)
        : new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    [currentDate],
  );

  const { data: currentData = [], isFetching: isFetchingCurrent } =
    useGetRoadmapQuery({
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
      provider,
    });

  const { data: nextData = [], isFetching: isFetchingNext } =
    useGetRoadmapQuery({
      month: nextMonth.getMonth() + 1,
      year: nextMonth.getFullYear(),
      provider,
    });

  const handleClick = useCallback(
    () => dispatch(toggleVisible(!isVisible)),
    [isVisible],
  );
  const handlePrevMonth = useCallback(
    () => setCurrentDate(prevMonth),
    [prevMonth],
  );
  const handleNextMonth = useCallback(
    () => setCurrentDate(nextMonth),
    [nextMonth],
  );
  const handleSetMonth = useCallback((date) => setCurrentDate(date), []);

  const handleDownloadEvent = useCallback(
    (type) => () =>
      triggerPlausibleEvent({
        name: plausibleEvents.keyConversionDownload,
        props: {
          page: `roadmap-${provider}`,
          content: `${type}-${provider}`,
        },
      }),
    [provider],
  );

  return (
    <>
      {isVisible && (
        <div className={styles.calendar}>
          <Calendar
            data={currentData.concat(nextData)}
            currentDate={currentDate.toString()}
            handleSetMonth={handleSetMonth}
          />
          <div className={styles.link}>
            <span onClick={handleClick}>Hide</span>
          </div>
        </div>
      )}
      <div className={classNames(styles.content, styles.actions)}>
        <div className={styles.roadmapBtn}>
          <div>Roadmap</div>
          <Button
            variant="icon"
            target="_blank"
            rel="noreferrer"
            download
            component={Link}
            to={pdfPath[provider]}
            startIcon={<DownloadIcon width={24} height={24} />}
            onClick={handleDownloadEvent('pdf')}
          >
            PDF
          </Button>
          <Button
            variant="icon"
            target="_blank"
            rel="noreferrer"
            download
            component={Link}
            to={xlsxPath[provider]}
            startIcon={<DownloadIcon width={24} height={24} />}
            onClick={handleDownloadEvent('xls')}
          >
            XLSX
          </Button>
        </div>
        <IconButton onClick={handleClick}>
          <CalendarIcon
            className={classNames(styles.calendarIcon, {
              [styles.active]: isVisible,
            })}
          />
        </IconButton>
      </div>
      <div className={classNames(styles.content, styles.itemsWrapper)}>
        <div>
          <div className={classNames(styles.activeMonths, styles.leftArrow)}>
            <ArrowCircleIcon onClick={handlePrevMonth} />
            {getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}
          </div>
          <div className={styles.scroll}>
            {isFetchingCurrent && <Loader />}
            {!isFetchingCurrent &&
              currentData.map((item) => <Item key={item.id} {...item} />)}
          </div>
        </div>
        <div>
          <div className={styles.activeMonths}>
            {getMonthName(nextMonth.getMonth())} {nextMonth.getFullYear()}
            <ArrowCircleIcon onClick={handleNextMonth} />
          </div>
          <div className={styles.scroll}>
            {isFetchingNext && <Loader />}
            {!isFetchingNext &&
              nextData.map((item) => <Item key={item.id} {...item} />)}
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
