import { otherDocumentsPath } from 'components/config/cloud';

export const data = [
  {
    Name: 'RNG',
    Jurisdiction: 'Bulgaria',
    Date: '07.03.2022',
    Link: `${otherDocumentsPath}/Bulgaria/RNG.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Croatia',
    Date: '07.03.2022',
    Link: `${otherDocumentsPath}/Croatia/RNG.zip`,
  },
  {
    Name: 'MOF_CRO_confirm_2021',
    Jurisdiction: 'Croatia',
    Date: '11.06.2021',
    Link: `${otherDocumentsPath}/Croatia/MOF_CRO_confirm_2021.pdf`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Czech Republic',
    Date: '24.08.2023',
    Link: `${otherDocumentsPath}/Czech+Republic/RNG.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Italy',
    Date: '27.03.2023',
    Link: `${otherDocumentsPath}/Italy/RNG.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Lithuania',
    Date: '07.03.2022',
    Link: `${otherDocumentsPath}/Lithuania/RNG.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Malta (MGA)',
    Date: '07.03.2022',
    Link: `${otherDocumentsPath}/Malta/RNG.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Portugal',
    Date: '14.02.2023',
    Link: `${otherDocumentsPath}/Portugal/RNG.zip`,
  },
  {
    Name: 'ISMS',
    Jurisdiction: 'Portugal',
    Date: '24.02.2023',
    Link: `${otherDocumentsPath}/Portugal/ISMS.zip`,
  },
  {
    Name: 'RNG',
    Jurisdiction: 'Romania',
    Date: '07.03.2022',
    Link: `${otherDocumentsPath}/Romania/RNG.zip`,
  },
];
