import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import useHotjar, { useHotjarSurvey } from 'hooks/hotjar';
import usePlausible from 'hooks/plausible';
import useResizeViewMode from 'hooks/resize';
import PropTypes from 'prop-types';

import { sidebarSelector } from 'store/slices/sidebar';

import { getAcr } from 'services/UserService';
import { triggerPlausibleEvent } from 'utils/plausible';

import { plausibleEvents } from 'components/config/plausibleEvents';

import styles from 'components/Layouts/App/AppLayout.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const AppLayout = ({ children }) => {
  const isVisibleSidebar = useSelector(sidebarSelector.getVisible);

  const handleLoginEvent = useCallback(() => {
    if (getAcr() === '1') {
      triggerPlausibleEvent({
        name: plausibleEvents.login,
      });
    }
  }, []);

  useResizeViewMode();
  usePlausible(handleLoginEvent);
  useHotjar();
  useHotjarSurvey();

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hideSidebar]: !isVisibleSidebar,
      })}
    >
      {children}
    </div>
  );
};

AppLayout.propTypes = propTypes;
export default AppLayout;
