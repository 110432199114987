import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import useGetTournaments from 'hooks/tournaments/tournaments';

import {
  useGetTournamentLeaderBoardQuery,
  useGetTournamentPrizeDistributionQuery,
  useGetTournamentRulesQuery,
} from 'store/api/tournamentService/recipe.api';
import { userSelector } from 'store/slices/user';

import { findPathPages } from 'utils/pages';
import { getTournamentById } from 'utils/tournaments';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import NotFound from 'components/pages/NotFound/NotFound';
import Banner from 'components/pages/Tournaments/Banner/Banner';
import HeaderGame from 'components/pages/Tournaments/Item/Content/HeaderGame';
import Leaderboard from 'components/pages/Tournaments/Item/Content/Leaderboard';
import TermsAndPrize from 'components/pages/Tournaments/Item/Content/TermsAndPrize';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES, getType } from 'components/config/tournaments';

import stylesGame from 'components/pages/Game/Game.module.scss';
import styles from 'components/pages/Tournaments/Item/Item.module.scss';
import stylesLayout from 'components/pages/Tournaments/Tournaments.module.scss';

const Item = () => {
  const userInfo = useSelector(userSelector.getInfo);
  const { item } = useParams();
  const tournamentEvents = useGetTournaments();
  const data = getTournamentById(tournamentEvents, item); // TODO: change to get tournament by id when api created
  const { data: leaderBoardData } = useGetTournamentLeaderBoardQuery(
    {
      event_id: item,
      user_id: userInfo?.id,
      size: 100,
    },
    { skip: !data || !userInfo, pollingInterval: 30000 },
  );
  const { data: prizesData } = useGetTournamentPrizeDistributionQuery(
    { event_id: item },
    { skip: !data },
  );
  const { data: rulesData } = useGetTournamentRulesQuery(
    { event_id: item },
    { skip: !data },
  );

  if (tournamentEvents === undefined) {
    return null;
  }

  if (!data) {
    return <NotFound />;
  }

  const results = {
    score: leaderBoardData?.currentPlayersEntry?.player?.score || 0,
    position: leaderBoardData?.currentPlayersEntry?.player?.rank || 0,
    prize: leaderBoardData?.currentPlayersEntry?.prize || '',
  };

  return (
    <>
      {data?.event.eventStatus === STATUSES.running ? (
        <HeaderGame
          item={data.event}
          results={results}
          player={leaderBoardData?.currentPlayersEntry?.player}
        />
      ) : (
        <div className={stylesLayout.wrapper}>
          <div className={styles.back}>
            <ArrowCircleLink
              path={findPathPages(PAGE_PATH.TOURNAMENTS)}
              title="Go to all tournaments"
              direction="left"
            />
          </div>
          <Banner
            item={data.event}
            category={getType[data.event.eventStatus]}
            results={results}
          />
        </div>
      )}
      {data.event.eventStatus !== STATUSES.comingSoon && (
        <div className={stylesLayout.wrapper}>
          {leaderBoardData && (
            <Leaderboard
              leaderboard={leaderBoardData.leaderboard.content}
              playerEntry={leaderBoardData.currentPlayersEntry}
            />
          )}
        </div>
      )}
      <div className={stylesLayout.wrapper}>
        <div
          className={classNames(stylesGame.content, stylesGame.technicalInfo)}
        >
          <div
            className={classNames(
              styles.section,
              styles.sectionTitle,
              styles.termsAndPrize,
            )}
          >
            <TermsAndPrize
              termsAndConditions={rulesData?.rules}
              placeAndPrize={[...(prizesData?.distributionEntries ?? [])].sort(
                (a, b) => {
                  const parseRank = (rank) => parseInt(rank.split('-')[0]);
                  return (
                    parseRank(a.rewardedRanks) - parseRank(b.rewardedRanks)
                  );
                },
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
