import StorageCollection from 'utils/storage/StorageCollection';

const StorageKey = 'allGamesState';
const StorageData = {
  viewType: 'column',
  visible: 'true',
};

const storage = new StorageCollection(StorageKey, StorageData);
export const getStorageData = () => storage.getAll();
export const setStorageData = (data) => storage.add(data);
