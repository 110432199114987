import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { data } from 'components/config/faq';
import { EMAILS } from 'config/organization';

import Owl from 'images/Support/owl.png';

import styles from './Support.module.scss';

const Support = () => {
  const [expand, setExpand] = useState('');
  const handleClick = useCallback(
    (item) => (item === expand ? setExpand('') : setExpand(item)),
    [expand],
  );

  return (
    <>
      <div className={styles.support}>
        <img src={Owl} alt="owl" />
        <div className={styles.title}>Support</div>
        <div className={styles.description}>
          <p>
            If you have any questions related to games, promotions, updates, or
            access, please contact your Key Account Manager.
          </p>
          <p>
            Alternatively, please email us at&nbsp;
            <a href={`mailto:${EMAILS.support}`}>{EMAILS.support}</a>, and we
            will respond as soon as possible.
          </p>
        </div>
      </div>
      <div className={styles.faq}>
        <div className={styles.content}>
          <div className={styles.title}>FAQ</div>
          {data.map((item, index) => (
            <div
              className={classNames(styles.item, {
                [styles.open]: expand === item.question,
              })}
              key={index}
            >
              <div
                className={styles.header}
                onClick={() => handleClick(item.question)}
              >
                <div className={styles.question}>
                  <div className={styles.index}>
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </div>
                  <div>{item.question}</div>
                </div>
                <div className={styles.arrowWrapper}>
                  <div
                    className={classNames(styles.arrow, {
                      [styles.arrowUp]: expand === item.question,
                      [styles.arrowDown]: expand !== item.question,
                    })}
                  ></div>
                </div>
              </div>
              {expand === item.question && (
                <div
                  className={styles.answer}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Support;
