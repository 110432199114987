import { useCallback } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { allGamesSelector } from 'store/slices/allGames';
import { viewModeSelector } from 'store/slices/viewMode';

import { formatViewDate } from 'utils/date';
import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import GameId from 'components/GameId/GameId';
import Type from 'components/GameItem/Type/Type';
import Button from 'components/UI/Button/Button';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import Popover from 'components/UI/Popover/Popover';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from './FullInfo.module.scss';

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    regionId: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        region: PropTypes.string,
      }),
    ).isRequired,
    type: PropTypes.string.isRequired,
    releaseDate: PropTypes.string.isRequired,
    images: PropTypes.shape({
      icon: PropTypes.string,
    }),
    rtp: PropTypes.arrayOf(PropTypes.number),
    rtpOptions: PropTypes.arrayOf(PropTypes.number),
    volatility: PropTypes.string,
    paylines: PropTypes.string,
    monetaryProperties: PropTypes.shape({
      minBet: PropTypes.number,
      maxBet: PropTypes.number,
      maxMultiplier: PropTypes.number,
      maxWin: PropTypes.number,
    }),
    miscellaneous: PropTypes.shape({
      promoTools: PropTypes.arrayOf(PropTypes.string),
      features: PropTypes.arrayOf(PropTypes.string),
      tags: PropTypes.arrayOf(PropTypes.string),
      marker: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const FullInfo = ({ item, onClick }) => {
  const checkedItems = useSelector(allGamesSelector.getSelectedItems);
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const isSimpleMode = viewMode === VIEW_MODE.SIMPLE;

  const { images, monetaryProperties, miscellaneous } = item;

  const handleCheck = useCallback((gameCode) => onClick(gameCode), [onClick]);
  const defaultText = <span className={styles.empty}>&mdash;</span>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.img}>
        <LazyLoadImage src={images?.icon ?? ComingSoonImg} alt={item.name} />
        {miscellaneous?.marker && <Type type={miscellaneous.marker} />}
        <Checkbox
          isOpacity
          checked={checkedItems.includes(item.gameCode)}
          className={styles.checkbox}
          onClick={() => handleCheck(item.gameCode)}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.gameTitle}>
          <div className={styles.title}>{item.name}</div>
          <div className={styles.categoryWrapper}>
            <span className={styles.category}>
              <span>{item.type}</span>
            </span>
            {!!item.regionId?.length && <GameId id={item.regionId} />}
          </div>
          <Button
            className={styles.moreBtn}
            component={Link}
            to={findPathPages(PAGE_PATH.GAME).replace(
              ':gameCode',
              item.gameCode,
            )}
          >
            More info
          </Button>
        </div>
        <div className={styles.gameInfo}>
          <div className={styles.column}>
            <div className={styles.row}>
              <span>Release date:</span>
              <span>{formatViewDate(new Date(item.releaseDate))}</span>
            </div>
            <div className={styles.row}>
              <span>RTP, %:</span>
              <span>
                {item.rtp?.length ? `${item.rtp.join('%, ')}%` : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>RTP Options:</span>
              <span>
                {item.rtpOptions?.length
                  ? `${item.rtpOptions.join('%, ')}%`
                  : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>Volatility:</span>
              <span>{item.volatility ?? defaultText}</span>
            </div>
            <div className={styles.row}>
              <span>Paylines:</span>
              <span>{item.paylines ?? defaultText}</span>
            </div>
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Min Bet, €:</span>
                <span>
                  {monetaryProperties?.minBet
                    ? `€ ${monetaryProperties.minBet}`
                    : defaultText}
                </span>
              </div>
            )}
          </div>
          <div className={styles.column}>
            {!isSimpleMode && (
              <div className={styles.row}>
                <span>Min Bet, €:</span>
                <span>
                  {monetaryProperties?.minBet
                    ? `€ ${monetaryProperties.minBet}`
                    : defaultText}
                </span>
              </div>
            )}
            <div className={styles.row}>
              <span>Max Bet, €:</span>
              <span>
                {monetaryProperties?.maxBet
                  ? `€ ${monetaryProperties.maxBet}`
                  : defaultText}
              </span>
            </div>
            <div className={styles.row}>
              <span>Max Multiplier:</span>
              <span>{monetaryProperties?.maxMultiplier ?? defaultText}</span>
            </div>
            <div className={styles.row}>
              <span>Max Win, €:</span>
              <span>
                {monetaryProperties?.maxWin
                  ? `€ ${monetaryProperties.maxWin}`
                  : defaultText}
              </span>
            </div>
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Promo tools:</span>
                <span className={styles.tag}>
                  {miscellaneous?.promoTools?.length ? (
                    <>
                      {miscellaneous?.promoTools
                        ?.slice(0, 2)
                        .map((value, index) => (
                          <span key={index}>{value}</span>
                        ))}
                      {miscellaneous?.promoTools?.length > 2 && (
                        <Popover
                          data={miscellaneous?.promoTools}
                          countMore={2}
                        />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Game Features:</span>
                <span className={styles.tag}>
                  {miscellaneous?.features?.length ? (
                    <>
                      <span>{miscellaneous?.features?.[0]}</span>
                      {miscellaneous?.features?.length > 1 && (
                        <Popover data={miscellaneous?.features} countMore={1} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
            {isSimpleMode && (
              <div className={styles.row}>
                <span>Tags:</span>
                <span className={styles.tag}>
                  {miscellaneous?.tags?.length ? (
                    <>
                      {miscellaneous?.tags?.slice(0, 2).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {miscellaneous?.tags?.length > 2 && (
                        <Popover data={miscellaneous?.tags} countMore={2} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            )}
          </div>
          {!isSimpleMode && (
            <div className={styles.column}>
              <div className={styles.row}>
                <span>Promo tools:</span>
                <span className={styles.tag}>
                  {miscellaneous?.promoTools?.length
                    ? miscellaneous?.promoTools?.map((value, index) => (
                        <span key={index}>{value}</span>
                      ))
                    : defaultText}
                </span>
              </div>
              <div className={styles.row}>
                <span>Game Features:</span>
                <span className={styles.tag}>
                  {miscellaneous?.features?.length ? (
                    <>
                      {miscellaneous?.features
                        ?.slice(0, 9)
                        .map((value, index) => (
                          <span key={index}>{value}</span>
                        ))}
                      {miscellaneous?.features?.length > 9 && (
                        <Popover data={miscellaneous?.features} countMore={9} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
              <div className={styles.row}>
                <span>Tags:</span>
                <span className={styles.tag}>
                  {miscellaneous?.tags?.length ? (
                    <>
                      {miscellaneous?.tags?.slice(0, 14).map((value, index) => (
                        <span key={index}>{value}</span>
                      ))}
                      {miscellaneous?.tags?.length > 14 && (
                        <Popover data={miscellaneous?.tags} countMore={14} />
                      )}
                    </>
                  ) : (
                    defaultText
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FullInfo.propTypes = propTypes;

export default FullInfo;
