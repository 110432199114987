class StorageCollection {
  constructor(key, storageData) {
    this.key = key;
    this.storageData = storageData;
  }
  add(data) {
    if (!data || !Object.keys(data).length) {
      this.clear();
    } else {
      const storageData = this.getAll();
      localStorage.setItem(
        this.key,
        JSON.stringify({ ...storageData, ...data }),
      );
    }
  }
  set(data) {
    this.clear();
    localStorage.setItem(this.key, JSON.stringify(data));
  }
  getAll() {
    return JSON.parse(localStorage.getItem(this.key)) || this.storageData;
  }
  clear() {
    localStorage.removeItem(this.key);
  }
}

export default StorageCollection;
