import { ReactComponent as BulgariaFlag } from 'images/Icons/Flags/Bulgaria.svg';
import { ReactComponent as ChineseFlag } from 'images/Icons/Flags/Chinese.svg';
import { ReactComponent as CroatiaFlag } from 'images/Icons/Flags/Croatia.svg';
import { ReactComponent as CzechRepublicFlag } from 'images/Icons/Flags/CzechRepublic.svg';
import { ReactComponent as EnglishFlag } from 'images/Icons/Flags/English.svg';
import { ReactComponent as FinlandFlag } from 'images/Icons/Flags/Finland.svg';
import { ReactComponent as FrenchFlag } from 'images/Icons/Flags/French.svg';
import { ReactComponent as GermanFlag } from 'images/Icons/Flags/German.svg';
import { ReactComponent as HungaryFlag } from 'images/Icons/Flags/Hungary.svg';
import { ReactComponent as IndonesiaFlag } from 'images/Icons/Flags/Indonesia.svg';
import { ReactComponent as ItalianFlag } from 'images/Icons/Flags/Italian.svg';
import { ReactComponent as JapanFlag } from 'images/Icons/Flags/Japan.svg';
import { ReactComponent as LithuaniaFlag } from 'images/Icons/Flags/Lithuania.svg';
import { ReactComponent as PolandFlag } from 'images/Icons/Flags/Poland.svg';
import { ReactComponent as PortugalFlag } from 'images/Icons/Flags/Portugal.svg';
import { ReactComponent as RomaniaFlag } from 'images/Icons/Flags/Romania.svg';
import { ReactComponent as SouthKoreaFlag } from 'images/Icons/Flags/SouthKorea.svg';
// import { ReactComponent as RussianFlag } from 'images/Icons/Flags/Russian.svg';
import { ReactComponent as SpainFlag } from 'images/Icons/Flags/Spain.svg';
import { ReactComponent as SwedenFlag } from 'images/Icons/Flags/Sweden.svg';
import { ReactComponent as ThailandFlag } from 'images/Icons/Flags/Thailand.svg';
import { ReactComponent as TurkishFlag } from 'images/Icons/Flags/Turkish.svg';
import { ReactComponent as UkraineFlag } from 'images/Icons/Flags/Ukraine.svg';
import { ReactComponent as UzbekistanFlag } from 'images/Icons/Flags/Uzbekistan.svg';
import { ReactComponent as VietnamFlag } from 'images/Icons/Flags/Vietnam.svg';

const shortMap = {
  en: 'English',
  uk: 'Ukrainian',
  ru: 'Russian (russia is a terrorist state)',
  zh: 'Chinese (Simple)',
  tr: 'Turkish',
  pt: 'Portuguese (Brazil)',
  'pt-pt': 'Portuguese',
  pl: 'Polish',
  fr: 'French',
  es: 'Spanish',
  de: 'German',
  it: 'Italian',
  ro: 'Romanian',
  bg: 'Bulgarian',
  hr: 'Croatian',
  zhtw: 'Chinese (Traditional)',
  th: 'Thai',
  ja: 'Japanese',
  ko: 'Korean',
  id: 'Indonesian',
  vi: 'Vietnamese',
  lt: 'Lithuanian',
  sv: 'Swedish',
  fi: 'Finnish',
  uz: 'Uzbek',
  ens: 'English (Social)',
  cs: 'Czech Republic',
  hu: 'Hungary',
};

export const Languages = {
  Bulgarian: 'bg',
  'Chinese (Simple)': 'zh',
  'Chinese (Traditional)': 'zhtw',
  Croatian: 'hr',
  'Czech Republic': 'cs',
  English: 'en',
  'English (Social)': 'ens',
  Finnish: 'fi',
  French: 'fr',
  German: 'de',
  Hungary: 'hu',
  Indonesian: 'id',
  Italian: 'it',
  Japanese: 'ja',
  Korean: 'ko',
  Lithuanian: 'lt',
  Polish: 'pl',
  Portuguese: 'pt-pt',
  'Portuguese (Brazil)': 'pt',
  Romanian: 'ro',
  'Russian (russia is a terrorist state)': 'ru',
  Spanish: 'es',
  Swedish: 'sv',
  Thai: 'th',
  Turkish: 'tr',
  Ukrainian: 'uk',
  Uzbek: 'uz',
  Vietnamese: 'vi',
};

export const Flags = {
  Bulgarian: (props) => <BulgariaFlag {...props} />,
  'Chinese (Simple)': (props) => <ChineseFlag {...props} />,
  'Chinese (Traditional)': (props) => <ChineseFlag {...props} />,
  Croatian: (props) => <CroatiaFlag {...props} />,
  English: (props) => <EnglishFlag {...props} />,
  'English (Social)': (props) => <EnglishFlag {...props} />,
  Finnish: (props) => <FinlandFlag {...props} />,
  French: (props) => <FrenchFlag {...props} />,
  German: (props) => <GermanFlag {...props} />,
  Indonesian: (props) => <IndonesiaFlag {...props} />,
  Italian: (props) => <ItalianFlag {...props} />,
  Japanese: (props) => <JapanFlag {...props} />,
  Korean: (props) => <SouthKoreaFlag {...props} />,
  Lithuanian: (props) => <LithuaniaFlag {...props} />,
  Polish: (props) => <PolandFlag {...props} />,
  Portuguese: (props) => <PortugalFlag {...props} />,
  'Portuguese (Brazil)': (props) => <PortugalFlag {...props} />,
  Romanian: (props) => <RomaniaFlag {...props} />,
  // 'Russian (russia is a terrorist state)': (props) => (
  //   <RussianFlag {...props} />
  // ),
  Spanish: (props) => <SpainFlag {...props} />,
  Swedish: (props) => <SwedenFlag {...props} />,
  Thai: (props) => <ThailandFlag {...props} />,
  Turkish: (props) => <TurkishFlag {...props} />,
  Ukrainian: (props) => <UkraineFlag {...props} />,
  Uzbek: (props) => <UzbekistanFlag {...props} />,
  Vietnamese: (props) => <VietnamFlag {...props} />,
  'Czech Republic': (props) => <CzechRepublicFlag {...props} />,
  Hungary: (props) => <HungaryFlag {...props} />,
};

export const getLanguages = (lang) => (shortMap[lang] ? shortMap[lang] : null);
