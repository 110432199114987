import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { finish, onboardingSelector } from 'store/slices/onboarding';

import { getPositionMenu } from 'utils/onboarding';

import Dialog from 'components/UI/Dialog/Dialog';

import { steps } from 'components/config/onboarding';

const OnBoarding = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(onboardingSelector.getVisible);
  const activeStep = useSelector(onboardingSelector.getStep);

  const handleClose = useCallback((event, reason) => {
    if (event && !['escapeKeyDown', 'backdropClick'].includes(reason)) {
      dispatch(finish());
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  if (steps[activeStep].menuPosition) {
    steps[activeStep].styles = getPositionMenu(steps[activeStep].menuPosition);
  }

  return (
    <Dialog
      handleClose={handleClose}
      open
      {...steps[activeStep]?.styles}
      options={{ hideScroll: true }}
    >
      {steps[activeStep].component({ handleClose })}
    </Dialog>
  );
};

export default OnBoarding;
