import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getType } from 'components/config/type';

import styles from './Type.module.scss';

const propTypes = {
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Type = ({ type, className }) => {
  const typeConfig = getType(type);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.content} style={{ color: typeConfig.color }}>
        {typeConfig.icon && typeConfig.icon({ className: styles.icon })}
        {typeConfig.icon && <span>{type}</span>}
        {!typeConfig.icon && type}
      </div>
    </div>
  );
};

Type.propTypes = propTypes;
export default Type;
