import Mascot1 from 'images/BrandAssets/Evoplay/Mascot/350x260_1.png';
import Mascot2 from 'images/BrandAssets/Evoplay/Mascot/350x260_2.png';
import Mascot3 from 'images/BrandAssets/Evoplay/Mascot/350x260_3.png';
import Mascot4 from 'images/BrandAssets/Evoplay/Mascot/350x260_4.png';

export const MascotData = [
  {
    image: Mascot1,
  },
  {
    image: Mascot2,
  },
  {
    image: Mascot3,
  },
  {
    image: Mascot4,
  },
];
