import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { promoTags } from 'components/config/promotions';

import styles from 'components/pages/Promotions/Tags/Tags.module.scss';

const propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

const Tags = ({ tags, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    {tags.map((tag) => (
      <div
        key={promoTags[tag].title}
        className={styles.tag}
        style={{
          color: promoTags[tag].color,
          backgroundColor: promoTags[tag].background,
        }}
      >
        {promoTags[tag].title}
      </div>
    ))}
  </div>
);

Tags.propTypes = propTypes;
export default Tags;
