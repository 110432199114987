import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/UI/Button/Button';

import { ReactComponent as ProfileCircle } from 'images/Icons/ProfileCircle.svg';
import { ReactComponent as TournamentsB2B } from 'images/Icons/TournamentsB2B.svg';

import styles from 'components/pages/Tournaments/Item/Item.module.scss';

const propTypes = {
  leaderboard: PropTypes.arrayOf(PropTypes.shape({})),
  playerEntry: PropTypes.shape({
    player: PropTypes.shape({
      rank: PropTypes.number,
      score: PropTypes.number,
      nickname: PropTypes.string,
      isCurrentPlayer: PropTypes.bool,
    }),
    prize: PropTypes.string,
  }),
  limit: PropTypes.number,
};

const Leaderboard = ({
  leaderboard = null,
  playerEntry = null,
  limit = 10,
}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = useCallback(
    () => setShowMore((showMore) => !showMore),
    [],
  );

  if (!leaderboard) return null;

  return (
    <>
      <div className={styles.leaderboard}>
        <div className={styles.leaderboardContent}>
          <div className={styles.leaderboardContentTitle}>Position</div>
          <div className={styles.leaderboardContentTitle}>Player ID</div>
          <div className={styles.leaderboardContentTitle}>Points</div>
          <div className={styles.leaderboardContentTitle}>Prize</div>
          {leaderboard
            .slice(0, showMore ? undefined : limit)
            .map(({ player, prize }, index) => {
              const { rank, score, nickname, isCurrentPlayer } = player;
              const optionClasses = classNames(
                styles.leaderboardContentOption,
                {
                  [styles.leaderboardContentOptionTop]: rank <= 5,
                  [styles.leaderboardContentOptionHighlight]: isCurrentPlayer,
                },
              );

              return (
                <React.Fragment key={index}>
                  <div className={optionClasses}>
                    {rank <= 5 && !isCurrentPlayer && (
                      <TournamentsB2B width="20" height="20" />
                    )}
                    {isCurrentPlayer && (
                      <ProfileCircle width={20} height={20} />
                    )}
                    {rank}
                  </div>
                  <div className={optionClasses}>{nickname}</div>
                  <div className={optionClasses}>{score}</div>
                  <div className={optionClasses}>{prize}</div>
                </React.Fragment>
              );
            })}
          {playerEntry &&
            ((showMore && playerEntry.player.rank >= leaderboard.length) ||
              (!showMore && playerEntry.player.rank > limit)) && (
              <>
                <div
                  className={classNames(
                    styles.leaderboardContentOption,
                    styles.leaderboardContentOptionHighlight,
                  )}
                >
                  <ProfileCircle width={20} height={20} />
                  {playerEntry.player.rank}
                </div>
                <div
                  className={classNames(
                    styles.leaderboardContentOption,
                    styles.leaderboardContentOptionHighlight,
                  )}
                >
                  {playerEntry.player.nickname}
                </div>
                <div
                  className={classNames(
                    styles.leaderboardContentOption,
                    styles.leaderboardContentOptionHighlight,
                  )}
                >
                  {playerEntry.player.score}
                </div>
                <div
                  className={classNames(
                    styles.leaderboardContentOption,
                    styles.leaderboardContentOptionHighlight,
                  )}
                >
                  {playerEntry.prize}
                </div>
              </>
            )}
        </div>
      </div>
      {leaderboard.length > limit && (
        <div className={styles.showMore}>
          <Button variant="text" onClick={toggleShowMore}>
            Show {showMore ? 'less' : 'more'}
          </Button>
        </div>
      )}
    </>
  );
};

Leaderboard.propTypes = propTypes;
export default Leaderboard;
