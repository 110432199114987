import { useSelector } from 'react-redux';

import { useGetComingSoonQuery } from 'store/api/configService/gameController';
import { viewModeSelector } from 'store/slices/viewMode';

import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Item from 'components/pages/Home/ComingSoon/Item/Item';

import { PAGE_PATH } from 'components/config/pages';
import { PROVIDERS } from 'config/organization';

import styles from './ComingSoon.module.scss';

const itemsSizes = {
  [VIEW_MODE.SIMPLE]: 5,
  [VIEW_MODE.WIDE]: 7,
};

const ComingSoon = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const { data } = useGetComingSoonQuery({
    provider: PROVIDERS.evoplay,
    count: itemsSizes[viewMode],
  });

  if (!data?.length) return null;

  return (
    <HomeComponentsLayout
      linkPath={findPathPages(PAGE_PATH.ROADMAP).replace(
        ':provider',
        PROVIDERS.evoplay,
      )}
      title="Coming soon"
      linkTitle="Evoplay Roadmap"
      styleWrapper={styles.wrapper}
      styleHeader={styles.header}
    >
      <AnimationLayout animation="fadeInRight">
        <div className={styles.content}>
          {data.map((item) => (
            <Item key={item.id} item={item} />
          ))}
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default ComingSoon;
