import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGetPostsQuery } from 'store/api/gameService/recipe.api';

import { findPathPages } from 'utils/pages';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Loader from 'components/Loader/Loader';
import List from 'components/pages/NewsAndUpdates/List/List';

import { categories } from 'components/config/newsAndUpdates';
import { PAGE_PATH } from 'components/config/pages';

import stylesNews from 'components/pages/NewsAndUpdates/NewsAndUpdates.module.scss';

const itemsSize = 5;

const LatestNews = () => {
  const { item, category } = useParams();
  const { data, isLoading } = useGetPostsQuery({
    size: itemsSize,
    query: `postType=${categories[category].postType};`,
  });

  const items = useMemo(
    () =>
      data?.content?.filter(({ id }) => id !== +item).slice(0, itemsSize - 1),
    [data, item],
  );

  if (isLoading) return <Loader />;

  return (
    <div className={stylesNews.newsSection}>
      <div className={stylesNews.content}>
        <div className={stylesNews.header}>
          <div className={stylesNews.title}>Latest {category}</div>
          <ArrowCircleLink
            path={findPathPages(PAGE_PATH.NEWS_CATEGORY).replace(
              ':category',
              category,
            )}
            title={`All ${
              category.charAt(0).toUpperCase() + category.slice(1)
            }`}
          />
        </div>
        <div className={stylesNews.list}>
          {items?.map((item) => (
            <List key={item.id} category={category} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
