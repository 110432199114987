import { createSlice } from '@reduxjs/toolkit';

import { getStorageData, setStorageData } from 'utils/storage/roadmap';

const initialState = {
  visible: getStorageData().visible,
};

export const roadmapMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (result.type === 'roadmap/toggleVisible') {
      setStorageData({ visible: getState().roadmap.visible });
    }
    return result;
  };
};

const roadmapSlice = createSlice({
  name: 'roadmap',
  initialState,
  reducers: {
    toggleVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const roadmapSelector = {
  getVisible: (state) =>
    state.roadmap.visible === true || state.roadmap.visible === 'true',
};

export const { toggleVisible } = roadmapSlice.actions;
export default roadmapSlice.reducer;
