import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import throttle from 'lodash.throttle';
import PropTypes from 'prop-types';

import { Select as MuiSelect } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';

import Loader from 'components/Loader/Loader';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import Close from 'components/UI/Icon/Close';
import ExpandMore from 'components/UI/Icon/ExpandMore';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import Accordion from 'components/UI/Select/Accordion/Accordion';
import Theme from 'components/UI/Select/Theme/Theme';

import { iconTitleMap, shortTitleMap } from 'components/config/filters';

import styles from './Select.module.scss';

const propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        year: PropTypes.number,
        months: PropTypes.arrayOf(PropTypes.string),
      }),
    ),
    PropTypes.shape({}),
  ]).isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  width: PropTypes.number,
  search: PropTypes.bool,
  widthDropdown: PropTypes.number,
  countTags: PropTypes.number,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  idElement: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const Loading = () => (
  <div className={styles.loader}>
    <Loader type="process" />
  </div>
);

const Select = ({
  items,
  title,
  label,
  search = false,
  width = 130,
  widthDropdown,
  countTags,
  handleSelect,
  value = '',
  multiple = false,
  idElement,
  disabled,
  loading,
}) => {
  const itemsValue = useMemo(
    () => (Array.isArray(items) ? items : Object.keys(items)),
    [items],
  );
  const [select, setSelect] = useState(value);
  const autocompleteRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(itemsValue);

  const handleChange = useCallback(
    (event) => {
      setSelect(event.target.value);
      handleSelect(label, event.target.value);
    },
    [handleSelect, label],
  );

  const handleClose = useCallback(() => {
    if (autocomplete.length !== itemsValue.length) {
      setAutocomplete(itemsValue);
    }
  }, [autocomplete, itemsValue]);

  const handleClickCloseTag = useCallback(
    (item) =>
      handleSelect(
        label,
        select.filter((el) => el !== item),
      ),
    [handleSelect, label, select],
  );
  const handleChangeAutocomplete = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      const findItem = event.target.value.toLowerCase().trim();
      if (findItem.length) {
        const filter = itemsValue.filter(
          (el) =>
            el.toLowerCase().indexOf(findItem) !== -1 ||
            (items[el] && items[el].toLowerCase().indexOf(findItem) !== -1),
        );
        setAutocomplete(filter);
      } else {
        setAutocomplete(itemsValue);
      }
      setTimeout(
        () => autocompleteRef.current && autocompleteRef.current.focus(),
        100,
      );
    }, 500),
    [itemsValue],
  );

  useEffect(() => {
    if (JSON.stringify(select) !== JSON.stringify(value)) {
      setSelect(value);
    }
  }, [value]);

  useEffect(() => {
    if (JSON.stringify(itemsValue) !== JSON.stringify(autocomplete)) {
      setAutocomplete(itemsValue);
    }
  }, [itemsValue]);

  return (
    <Theme width={width} widthDropdown={widthDropdown} checkbox={!!countTags}>
      <FormControl>
        <InputLabel htmlFor={idElement || title}>{title}</InputLabel>
        <MuiSelect
          multiple={multiple}
          IconComponent={!loading ? ExpandMore : Loading}
          value={select}
          onChange={handleChange}
          onClose={handleClose}
          input={
            <OutlinedInput label={title} name={title} id={idElement || title} />
          }
          disabled={disabled || loading}
          renderValue={(selected) => {
            if (!countTags) {
              return <div className={styles.selected}>{selected}</div>;
            }
            return (
              <div className={styles.tagWrapper}>
                {selected.slice(0, countTags).map((item, index) => (
                  <div key={index} className={styles.tag}>
                    {shortTitleMap[item] || item}
                  </div>
                ))}
                {selected.length > countTags && (
                  <div className={styles.tagMore}>
                    +{selected.length - countTags}
                  </div>
                )}
              </div>
            );
          }}
        >
          {(search || select.length > 0) && (
            <ListSubheader
              onKeyDown={(e) => e.stopPropagation()}
              className={styles.tagsArea}
            >
              {search && (
                <SearchInput
                  handleChange={handleChangeAutocomplete}
                  ref={autocompleteRef}
                />
              )}
              {countTags && select.length > 0 && (
                <div className={styles.tagWrapper}>
                  {select.map((item, index) => (
                    <div key={index} className={styles.tag}>
                      {item}
                      <Close onClick={() => handleClickCloseTag(item)} />
                    </div>
                  ))}
                </div>
              )}
            </ListSubheader>
          )}
          {select && !multiple && !autocomplete.includes(select) && (
            <MenuItem value={select} />
          )}
          {autocomplete.map((option, index) => {
            if (typeof option === 'string') {
              return (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{ padding: countTags ? 0 : '10px 0' }}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  {countTags && <Checkbox checked={select.includes(option)} />}
                  <ListItemText
                    primary={
                      items[option]
                        ? `${option} - ${items[option]}`
                        : iconTitleMap[option] || option
                    }
                  />
                </MenuItem>
              );
            }
            if (typeof option === 'object') {
              return (
                <ListSubheader
                  className={styles.accordionWrapper}
                  key={option.title}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <Accordion
                    defaultOpen={!index && !select.length}
                    label={label}
                    value={select}
                    option={option}
                    handleSelect={handleSelect}
                  />
                </ListSubheader>
              );
            }
          })}
        </MuiSelect>
      </FormControl>
    </Theme>
  );
};

Select.propTypes = propTypes;
export default Select;
