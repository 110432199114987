const cloudPath = 'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/';
export const gamesPath = `${cloudPath}Games/`;
export const gamePath = (gameCode) => `${gamesPath}${gameCode}/`;
export const bingoPath = `${cloudPath}Bingo`;
export const promotionsPath = `${cloudPath}Promotions`;
export const brandAssetsPath = `${cloudPath}Brand+Assets`;
export const documentationPath = `${cloudPath}Documentation`;
export const otherDocumentsPath = `${cloudPath}Other+documents`;
export const roadmapPath = `${cloudPath}Files/Roadmap`;
export const gameJurisdictionListPath = `${cloudPath}Excel+Files/Evoplay+Game+Jurisdiction.xlsx`;

export const getIcon = (gameCode) => `${gamePath(gameCode)}Components/icon.jpg`;
export const getLogo = (gameCode) => `${gamePath(gameCode)}Components/logo.png`;
export const getBackground = (gameCode) =>
  `${gamePath(gameCode)}Components/bg.jpg`;
export const getSlider = (gameCode) => ({
  sliderLarge: `${gamePath(gameCode)}Components/slider_large.webm`,
  sliderSmall: `${gamePath(gameCode)}Components/slider_small.webm`,
});
export const getCertificate = (gameCode) =>
  `${gamePath(gameCode)}Certificates.zip`;
