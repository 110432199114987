import { createSlice } from '@reduxjs/toolkit';

import { getStorageData, setStorageData } from 'utils/storage/tournamentPopup';

const initialState = {
  countShow: getStorageData().countShow,
  tournament: getStorageData().tournament,
};

export const tournamentPopupMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (result.type === 'tournamentPopup/close') {
      setStorageData({ countShow: getState().tournamentPopup.countShow });
    }
    if (result.type === 'tournamentPopup/open') {
      setStorageData({
        tournament: getState().tournamentPopup.tournament,
        countShow: getState().tournamentPopup.countShow,
      });
    }
    return result;
  };
};

const tournamentPopupSlice = createSlice({
  name: 'tournamentPopup',
  initialState,
  reducers: {
    open: (state, action) => {
      if (state.tournament !== action.payload) {
        state.countShow = 1;
      }
      state.tournament = action.payload;
    },
    close: (state) => {
      state.countShow++;
    },
  },
});

export const tournamentPopupSelector = {
  getVisible: (state) => state.tournamentPopup.countShow <= 2,
};

export const { open, close } = tournamentPopupSlice.actions;
export default tournamentPopupSlice.reducer;
