import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { step } from 'store/slices/onboarding';

import Button from 'components/UI/Button/Button';

import styles from 'components/OnBoarding/OnBoarding.module.scss';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  previousStep: PropTypes.string,
  nextStep: PropTypes.string,
  percent: PropTypes.number,
};

const SideBarStep = ({
  handleClose,
  title,
  subTitle,
  nextStep = null,
  previousStep = null,
  percent = null,
}) => {
  const dispatch = useDispatch();
  const handleNext = useCallback(
    () => (nextStep ? dispatch(step(nextStep)) : ''),
    [nextStep],
  );
  const handlePrevious = useCallback(
    () => (previousStep ? dispatch(step(previousStep)) : ''),
    [previousStep],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div
          className={styles.subTitle}
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      </div>
      <div className={styles.progressBar}>
        <div style={{ width: `${percent}%` }} />
      </div>
      <div className={styles.actions}>
        {previousStep && (
          <Button onClick={handlePrevious} variant="secondary">
            Previous
          </Button>
        )}
        {nextStep && (
          <Button
            sx={!previousStep ? { width: '100%' } : {}}
            onClick={handleNext}
          >
            Next
          </Button>
        )}
        {!previousStep && !nextStep && (
          <Button onClick={handleClose} sx={{ width: '100%' }}>
            Finish tour
          </Button>
        )}
      </div>
    </div>
  );
};

SideBarStep.propTypes = propTypes;
export default SideBarStep;
