import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import Tooltip from 'components/UI/Tooltip/Tooltip';

import styles from './GameId.module.scss';

const propTypes = {
  id: PropTypes.arrayOf(
    PropTypes.shape({
      region: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const mapRegion = {
  EU: 'Europe',
  ASIA: 'Asia',
};

const GameId = ({ id }) => {
  const component = useMemo(() => {
    if (id.length > 1 && id[0].id !== id[1].id) {
      return id.map((item) => (
        <Tooltip title={mapRegion[item.region]} key={item.id}>
          <span>{item.id}</span>
        </Tooltip>
      ));
    }

    return <span>{id[0].id}</span>;
  }, [id]);

  return (
    <div className={styles.wrapper}>
      <span>ID</span>
      {component}
    </div>
  );
};

GameId.propTypes = propTypes;
export default GameId;
