export const PROVIDERS = {
  evoplay: 'evoplay',
  slotopia: 'slotopia',
};

export const EMAILS = {
  tech: '@evoplay.tech',
  games: '@evoplay.games',
  support: 'support@evoplay.games',
};
