import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'components/pages/Contents/Contents.module.scss';

const propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        span: PropTypes.number,
      }),
      PropTypes.node,
    ]),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          rowClass: PropTypes.string,
          element: PropTypes.node,
        }),
        PropTypes.node,
      ]),
    ),
  ),
};

const ContentsTable = ({ columns = [], rows = [] }) => (
  <div className={styles.table}>
    <div className={styles.tableColumns}>
      {columns.map(({ title, span }, index) => (
        <div
          key={index}
          className={styles.tableColumn}
          style={{ gridColumn: span }}
        >
          {title}
        </div>
      ))}
    </div>
    {rows.map((items, rowIndex) => (
      <div key={rowIndex} className={styles.tableRows}>
        {columns.map(({ span }, itemIndex) => (
          <div
            key={`${rowIndex}${itemIndex}`}
            className={classNames(styles.tableItem, {
              [items[itemIndex]?.rowClass]: !!items[itemIndex]?.rowClass,
            })}
            style={{ gridColumn: span }}
          >
            {items[itemIndex]?.element ?? items[itemIndex]}
          </div>
        ))}
      </div>
    ))}
  </div>
);

ContentsTable.propTypes = propTypes;
export default ContentsTable;
