import { api } from 'store/api/configService';

const enumsController = api.injectEndpoints({
  endpoints: (build) => ({
    getGameTypes: build.query({
      query: () => ({
        url: '/config-service/v1/enums/game-types',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGameMarkers: build.query({
      query: () => ({
        url: '/config-service/v1/enums/markers',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGameJurisdictions: build.query({
      query: () => ({
        url: '/config-service/v1/enums/jurisdictions',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGameLanguages: build.query({
      query: () => ({
        url: '/config-service/v1/enums/languages',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGamePromoTools: build.query({
      query: () => ({
        url: '/config-service/v1/enums/promo-tools',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGameProviders: build.query({
      query: () => ({
        url: '/config-service/v1/enums/providers',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
    getGameVolatilities: build.query({
      query: () => ({
        url: '/config-service/v1/enums/volatilities',
      }),
      transformResponse: (response) =>
        response?.map(({ name, code }) => ({ label: name, value: code })),
    }),
  }),
});

export const {
  useGetGameTypesQuery,
  useGetGameMarkersQuery,
  useGetGameJurisdictionsQuery,
  useGetGameLanguagesQuery,
  useGetGamePromoToolsQuery,
  useGetGameProvidersQuery,
  useGetGameVolatilitiesQuery,
} = enumsController;
