import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { formatViewDate } from 'utils/date';
import { findPathPages } from 'utils/pages';

import Type from 'components/GameItem/Type/Type';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from 'components/pages/Home/ComingSoon/Item/Item.module.scss';

const propTypes = {
  item: PropTypes.shape({
    images: PropTypes.shape({
      icon: PropTypes.string,
    }),
    name: PropTypes.string.isRequired,
    gameCode: PropTypes.string.isRequired,
    releaseDate: PropTypes.string,
    miscellaneous: PropTypes.shape({
      marker: PropTypes.string,
    }),
  }).isRequired,
};

const Item = ({
  item: { images, name, releaseDate, gameCode, miscellaneous },
}) => (
  <Link
    className={styles.wrapper}
    to={findPathPages(PAGE_PATH.GAME).replace(':gameCode', gameCode)}
  >
    {miscellaneous?.marker && <Type type={miscellaneous.marker} />}
    <img src={images?.icon ?? ComingSoonImg} alt={name} />
    <div className={styles.title}>{name}</div>
    {releaseDate && (
      <div className={styles.dateRelease}>
        {formatViewDate(new Date(releaseDate))}
      </div>
    )}
  </Link>
);

Item.propTypes = propTypes;
export default Item;
