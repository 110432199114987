import { createSlice } from '@reduxjs/toolkit';

import { dateStringToDate } from 'utils/date';

import { data as initialData } from 'components/config/otherDocuments';

const initialState = {
  data: initialData,
  jurisdiction: '',
  filter: '',
  range: [],
};

const filterData = ({ jurisdiction, filter, range }) => {
  let resultData = initialData;
  if (jurisdiction) {
    resultData = resultData.filter(
      (item) => item.Jurisdiction === jurisdiction,
    );
  }
  if (filter) {
    const findName = filter.toLowerCase().trim();
    resultData = resultData.reduce((acc, el) => {
      let findItems = [];
      if (el.Name.toLowerCase().indexOf(findName) !== -1) {
        findItems = el;
      } else if (el.subItems) {
        findItems = el.subItems.filter(
          (elSub) => elSub.Name.toLowerCase().indexOf(findName) !== -1,
        );
      }
      return acc.concat(findItems);
    }, []);
  }
  if (range.length) {
    resultData = resultData.filter((item) =>
      range.length === 2
        ? dateStringToDate(item.Date) >= dateStringToDate(range[0]) &&
          dateStringToDate(item.Date) <= dateStringToDate(range[1])
        : dateStringToDate(item.Date) >= dateStringToDate(range[0]),
    );
  }

  return resultData;
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setJurisdiction: (state, action) => {
      state.jurisdiction = action.payload;
      state.data = filterData(state);
    },
    rangeDate: (state, action) => {
      state.range = action.payload;
      state.data = filterData(state);
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.data = filterData(state);
    },
    clearFilters: () => initialState,
  },
});

export const documentsSelector = {
  getJurisdiction: (state) => state.documents.jurisdiction,
  getRange: (state) => state.documents.range,
  getData: (state) => state.documents.data,
};

export const { setJurisdiction, rangeDate, setFilter, clearFilters } =
  documentsSlice.actions;
export default documentsSlice.reducer;
