import { useEffect } from 'react';

const useContentScrollLock = (container, disabled) => {
  useEffect(() => {
    const handleScroll = (e) => {
      if (disabled) {
        e.preventDefault();
      }
    };

    const initialWidth = container.clientWidth;
    container.style.overflowY = disabled ? 'hidden' : 'auto';
    const scrollbarWidth = container.clientWidth - initialWidth;
    container.style.marginRight = disabled ? `${scrollbarWidth}px` : null;

    document.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      document.removeEventListener('wheel', handleScroll);
    };
  }, [disabled]);
};

export default useContentScrollLock;
