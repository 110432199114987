import { createBaseApi } from 'store/api';

import { formatViewDate } from 'utils/date';

export const api = createBaseApi({
  reducerPath: 'gameService',
});

export const recipeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBetCurrency: build.query({
      query: ({ currency, gameCode }) => ({
        url: '/game-service/api/v1/bet-currency',
        params: { currency, game_code: gameCode },
      }),
    }),
    getRules: build.query({
      query: ({ game_code, lang }) => ({
        url: '/game-service/api/v1/rules',
        params: { game_code: game_code.join(','), lang: lang.join(',') },
      }),
    }),
    getPosts: build.query({
      query: (params) => ({
        url: '/config-service/api/v1/posts',
        params: {
          sort: 'publicationDate,desc',
          ...params,
        },
      }),
      transformResponse: (response) => ({
        ...response,
        content: response?.content?.map((item) => ({
          ...item,
          img: item.postImage,
          background: item.backgroundImage,
          date: formatViewDate(new Date(item.publicationDate)),
        })),
      }),
    }),
    getPost: build.query({
      query: (id) => ({
        url: `/config-service/api/v1/posts/${id}`,
      }),
      transformResponse: (response) => ({
        ...response,
        img: response.postImage,
        background: response.backgroundImage,
        date: formatViewDate(new Date(response.publicationDate)),
      }),
    }),
  }),
});

export const {
  useGetBetCurrencyQuery,
  useGetRulesQuery,
  useGetPostsQuery,
  useGetPostQuery,
} = recipeApi;
