import SideBarStep from 'components/OnBoarding/Step/SideBarStep';
import Welcome from 'components/OnBoarding/Step/Welcome';

export const steps = {
  Welcome: {
    component: (props) => <Welcome {...props} nextStep="Roadmap" />,
  },
  Roadmap: {
    component: (props) => (
      <SideBarStep
        {...props}
        nextStep="Games"
        title="Let’s start from the Roadmap section."
        subTitle="You can get information on upcoming games and download a roadmap. Just select the provider's page: Evoplay or Slotopia."
        percent={20}
      />
    ),
    menuPosition: 'Roadmap',
  },
  Games: {
    component: (props) => (
      <SideBarStep
        {...props}
        nextStep="Promotions"
        previousStep="Roadmap"
        title="Let’s continue with the All Games section."
        subTitle="<p>It also comprises 2 pages: Evoplay and Slotopia.</p>You can access all the information about released games, download it and promotional materials."
        percent={40}
      />
    ),
    menuPosition: 'All games',
  },
  Promotions: {
    component: (props) => (
      <SideBarStep
        {...props}
        nextStep="Documentation"
        previousStep="Games"
        title="Next section: Promotions."
        subTitle="Here, you can discover all the information about upcoming, ongoing, or completed promotions, including Tournaments and Prize Drops."
        percent={60}
      />
    ),
    menuPosition: 'Promotions',
  },
  Documentation: {
    component: (props) => (
      <SideBarStep
        {...props}
        nextStep="Final"
        previousStep="Promotions"
        title="This is where you'll find our documentation."
        subTitle="You can find and download documents about integration, back office, and supported currencies right here."
        percent={80}
      />
    ),
    menuPosition: 'Documentation',
  },
  Final: {
    component: (props) => (
      <SideBarStep
        {...props}
        title="And the final stop on our tour."
        subTitle="You can find answers to frequently asked questions on the Support page."
        percent={100}
      />
    ),
    menuPosition: 'Support',
  },
};
