import { useEffect, useState } from 'react';

import { isDate } from 'lodash';

const useCountdown = (targetDate) => {
  const calculateTimeLeft = () => {
    if (!isDate(targetDate)) {
      return false;
    }

    const difference = targetDate.getTime() - new Date().getTime();

    if (difference <= 0) {
      return false;
    }

    let remainingTime = {};

    remainingTime.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    remainingTime.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    remainingTime.minutes = Math.floor((difference / 1000 / 60) % 60);
    remainingTime.seconds = Math.floor((difference / 1000) % 60);

    return remainingTime;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return timeLeft;
};

export default useCountdown;
