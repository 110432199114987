import React from 'react';

import PropTypes from 'prop-types';

import { Tooltip as MuiTooltip } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          '& > div': {
            marginBottom: '7px !important',
          },
        },
      },
    },
  },
});

const Tooltip = ({ children, title }) => (
  <ThemeProvider theme={theme}>
    <MuiTooltip title={title} arrow placement="top">
      {children}
    </MuiTooltip>
  </ThemeProvider>
);

Tooltip.propTypes = propTypes;
export default Tooltip;
