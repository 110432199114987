import { createSlice } from '@reduxjs/toolkit';

import { getStorageData, setStorageData } from 'utils/storage/onboarding';

import { steps } from 'components/config/onboarding';

const initialState = {
  visible: getStorageData().visible,
  step: 'Welcome',
};

export const onboardingMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (result.type === 'onboarding/finish') {
      setStorageData({ visible: getState().onboarding.visible });
    }
    return result;
  };
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    step: (state, action) => {
      state.step = action.payload;
    },
    finish: (state) => {
      state.visible = false;
    },
  },
});

export const onboardingSelector = {
  getVisible: (state) =>
    Boolean(
      state.onboarding.visible === true || state.onboarding.visible === 'true',
    ) && !!steps[state.onboarding.step],
  getStep: (state) => state.onboarding.step,
};

export const { finish, step } = onboardingSlice.actions;
export default onboardingSlice.reducer;
