/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';

import ContentsTable from 'components/pages/Contents/ContentsTable';

import { EMAILS } from 'config/organization';

import styles from 'components/pages/Contents/Contents.module.scss';

const PrivacyNotice = () => (
  <div>
    <div className={styles.metaData}>
      <span>Version published: 1.0</span>
      <span>Date published: March, 2024</span>
    </div>
    <p>
      Welcome to the Privacy Notice of Evoplay Entertainment B.V. (“
      <strong>Company</strong>”). In this document, we will explain how we,
      referred to as "we," "us," or "our," gather and handle data about you when
      you access or use our Services and Website available at{' '}
      <Link to="/">{location.origin}</Link> (“<strong>Website</strong>”).
    </p>
    <p>
      Your trust in us is of paramount importance, and we deeply appreciate your
      decision to entrust us with your data. As a responsible entity,
      safeguarding your data is our topmost priority. Our objective is to uphold
      your trust, and to accomplish this, we implement all essential and
      reasonable measures to safeguard any personal data you provide to us.
    </p>
    <h3 className={styles.header}>TERMS AND DEFINITIONS</h3>
    <p>The following terms shall have the following meaning:</p>
    <p>
      <strong>“Service” or “Services”</strong> — access to our Website (
      <Link to="/">{location.origin}</Link>), provided by our Company, together
      with its affiliates, officers, directors, employees, agents, and
      subsidiaries.
    </p>
    <p>
      <strong>“Data Controller”</strong> — The entity responsible for
      determining the purposes and means of processing of Personal Data.
    </p>
    <p>
      <strong>“Personal Data”</strong> — any information that relates to an
      identified or identifiable individual.
    </p>
    <p>
      <strong>“European Economic Area (“EEA”)</strong> — the member states of
      the European Union and three additional countries, namely Iceland,
      Liechtenstein, and Norway.
    </p>
    <h3 className={styles.header}>Data controller</h3>
    <p>
      Evoplay Entertainment B.V., a Company incorporated in Curaçao, under
      registration number 146580, with registered address: Fransche Bloemweg 4,
      Willemstad, Curacao, is the Data Controller that is responsible for the
      Personal Data collected and used within the scope of this Privacy Notice.
    </p>
    <p>
      Our contacts in the European Union: Evoplay Entertainment LTD, the company
      incorporated in the Republic of Cyprus, with its registered number НЕ
      406912, with its registered address at Spyrou Kyprianou, 79, Protopapas
      bldg., 2nd floor, Flat/Office 201, Limassol, Cyprus, 3076.
    </p>
    <p>
      Our email address:{' '}
      <a href={`mailto:${EMAILS.support}`}>{EMAILS.support}</a>
    </p>
    <h3 className={styles.header}>
      WHAT PERSONAL DATA TO WE COLLECT ABOUT YOU
    </h3>
    <p>
      <strong>Contact data:</strong> When you get in touch with us via the
      "Access to Client Area" form, we kindly request you to complete the
      contact form by providing your company name, email, and link to your
      company website (your residence of work). The data you provide is
      processed with the intention of offering you the necessary support and
      information you have requested from us. Additionally, we use this data to
      maintain precise records of the details we have received from you.
    </p>
    <p>
      <strong>Communication Data:</strong> Correspondence and communication
      exchanged between you and us through various channels, including email or
      our Website.
    </p>
    <p>
      <strong>Login data:</strong> When you use the Website on any device, we
      may additionally collect and store device data including; device ID and
      geolocation data such as IP addresses. We collect this information to gain
      an understanding of where the Website is being accessed and in what form,
      to help improve our Website and Services.
    </p>
    <p>
      <strong>Analytics Data:</strong> We may collect information about how you
      use the Website, pages visited, time spent, features used, etc.
    </p>
    <p>
      <strong>Business Data:</strong> This information includes information
      about your website, business industry, markets, goods or services you
      offer, etc.
    </p>
    <h3 className={styles.header}>
      WHAT HAPPENS IF YOU DON’T GIVE US YOUR DATA
    </h3>
    <p>
      We hold your privacy in high regard and recognize that the decision to
      share Personal Data is a personal choice. Nonetheless, it is vital to
      acknowledge that certain services may necessitate specific Personal Data
      to be furnished to ensure the optimal delivery of the desired experience.
      If you opt not to provide us with the requested data, it may restrict our
      capacity to furnish certain services or fully meet your requirements. We
      strive to offer a customized and personalized experience, and while we
      respect your decision, it is crucial to understand that withholding
      certain data could affect the extent of our offerings and the level of
      services we can provide.
    </p>
    <h3 className={styles.header}>HOW WE USE THE DATA WE COLLECT</h3>
    <p>
      In the table below we indicate the business purposes where we may use your
      personal data:
    </p>
    <ContentsTable
      columns={[
        { title: 'Purpose' },
        { title: 'Personal Data' },
        { title: 'Legal Basis' },
      ]}
      rows={[
        [
          'To provide Services to you.',
          'Contact Data, Communication Data.',
          'Fulfilment of a contract with you and legitimate interests.',
        ],
        [
          'To communicate with you and handle your inquiries, requests, comments, and feedback etc.',
          'Contact data, Communication Data.',
          'Legitimate interests to handle, adequately and in a timely manner, your’ inquiries, complaints, and suggestions regarding the services we provide.',
        ],
        [
          'To detect, prevent, or investigate potential security incidents or fraud.',
          'Login data, Analytics data.',
          'Legitimate interests to ensure security for our systems and Services provided.',
        ],
        [
          'To establish, exercise and defend legal claims.',
          'Contact data, Communication Data, Login data, Analytics data.',
          'Legitimate interests to defend ourselves in legal claims and comply with our legal obligations.',
        ],
        [
          'To perform analytics, quality control, market research, and determine the effectiveness of our websites, promotional campaigns, and develop new products and services',
          'Login data, Analytics data.',
          'Legitimate interests to perform marketing and research activities in order to provide you better Service.',
        ],
        [
          <>
            We may use the data we collect to market our Services, in particular
            to:
            <ul>
              <li>Notify you of news and updates related to our business;</li>
              <li>Perform market analysis/research.</li>
            </ul>
          </>,
          'Contact data, Communication Data, Login data, Analytics data, Business data.',
          'Legitimate interest in processing Personal Data for marketing purposes. However, we send you marketing communications only if you consent to marketing.',
        ],
      ]}
    />

    <h3 className={styles.header}>DATA RETENTION PERIOD</h3>
    <p>
      The retention of Personal Data is determined either by legal and
      regulatory obligations or for the duration necessary to achieve the
      purpose for which the personal data was collected. We will retain and use
      your Personal Data to the extent necessary to comply with our legal
      obligations.
    </p>

    <h3 className={styles.header}>DATA SHARING AND DISCLOSURE</h3>
    <p>
      Your Personal Data may be shared with contracted third parties for diverse
      business purposes. Such sharing of Personal Data may occur on legal
      grounds, including legitimate interests, to fulfill the agreed-upon
      services or in adherence to mutually concluded contractual obligations.
    </p>
    <p>
      Your Personal Data might be disclosed to third parties in the following
      situations:
    </p>
    <p>
      <strong>Third-party service providers:</strong> We enlist the assistance
      of third-party service providers to support us in delivering
      website-related services. These service providers may be located either
      within or outside the European Economic Area ("EEA").
    </p>
    <p>
      <strong>Compliance with the Law:</strong> We may reveal your Personal Data
      to courts, law enforcement or governmental authorities, or authorized
      third parties if and to the extent it is obligatory or permitted by law,
      or if such disclosure is reasonably necessary:
    </p>
    <ul>
      <li>to comply with our legal obligations;</li>
      <li>
        to adhere to legal procedures and address claims brought against us;
      </li>
      <li>
        to respond to inquiries concerning a criminal investigation or alleged
        or suspected illegal activity, or any other activity that may
        potentially subject us, you, or any other of our users to legal
        liability.
      </li>
    </ul>
    <p>
      <strong>Business transfer:</strong> In the event that we engage in or are
      part of any merger, acquisition, reorganization, sale of assets,
      bankruptcy, or insolvency event, it is possible that we may sell,
      transfer, or share some or all of our assets, including your Personal
      Data, as part of or in anticipation of such a transaction. In such event,
      we will notify you before your Personal Data is transferred.
    </p>

    <h3 className={styles.header}>INTERNATIONAL TRANSFER</h3>
    <p>
      We may transfer your Personal Data to service providers based outside the
      European Economic Area.
    </p>
    <p>
      To ensure the protection of Personal Data when transferring it outside the
      European Economic Area (EEA), the following measures can be implemented:
    </p>
    <span className={styles.listItem}>Adequacy Decision:</span>
    <p>
      Transfer Personal Data to countries or organizations that have received an
      adequacy decision from the European Commission. Adequacy decisions confirm
      that the destination country provides a level of data protection
      equivalent to that in the EEA. For more information about adequacy
      decisions, click{' '}
      <a
        href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </p>
    <span className={styles.listItem}>Appropriate Safeguards:</span>
    <p>
      In order to transfer Personal Data to recipients who do not have an
      adequacy decision, we may employ standard contractual clauses that have
      been approved by the European Commission or other recognized legal
      mechanisms for data transfers. For more information about standard
      contractual clauses, click{' '}
      <a
        href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </a>
      .
    </p>

    <h3 className={styles.header}>YOUR RIGHTS</h3>

    <p>
      You possess various rights concerning your Personal Data. These rights
      include the ability to request access, rectification, erasure, objection,
      restriction of personal data processing, withdrawal of consent if given,
      and the option to transfer your personal data. Moreover, you retain the
      right to contact the data protection supervisory authority in your country
      of residence.
    </p>
    <p>
      We are committed to addressing all individual requests aimed at exercising
      their data protection rights. To ensure a secure and efficient response
      and safeguard personal data from unauthorized disclosure, we may require
      verification of your identity or request additional information.
    </p>
    <p>
      If you wish to exercise your data protection rights, please contact us:{' '}
      <a href={`mailto:${EMAILS.support}`}>{EMAILS.support}</a>
    </p>
    <p>
      If you make a request, we have one month to respond to you and you will
      not be charged for exercising your rights.
    </p>
    <ul>
      <li>
        <div className={styles.bold}>Right to erase your Personal Data</div>
        Under specific circumstances, you have the right to request the erasure
        of your personal data, particularly when it is no longer necessary for
        the purposes for which it was collected.
      </li>
      <li>
        <div className={styles.bold}>Right to access your Personal Data</div>
        You have the right to request copies of your Personal Data.
      </li>
      <li>
        <div className={styles.bold}>Right to rectify your personal data</div>
        You have the right to request that we correct any information you
        believe is inaccurate. You also have the right to request that we
        complete the information you believe is incomplete.
      </li>
      <li>
        <div className={styles.bold}>
          Right to Restrict Processing of Personal Data
        </div>
        You have the right to request that we restrict the processing of your
        Personal Data.
      </li>
      <li>
        <div className={styles.bold}>Right to Data Portability</div>
        You have the right to request that we transfer the data that we have
        collected to another organization, or directly to you, under certain
        conditions.
      </li>
      <li>
        <div className={styles.bold}>Right to Withdraw Consent</div>
        If you have agreed on data processing based on your consent, you have
        the right at any time withdraw your consent.
      </li>
      <li>
        <div className={styles.bold}>Right to Restrict Processing</div>
        You have the right to request that we restrict the processing of your
        Personal Data.
      </li>
      <li>
        <div className={styles.bold}>
          Right to complain to the data protection supervisory authority
        </div>
        You have the right to lodge complaints regarding your Personal Data
        processing to the relevant data protection supervisory authority.
      </li>
    </ul>
    <h3 className={styles.header}>CCPA PRIVACY RIGHTS</h3>
    <p>
      The California Consumer Privacy Act (CCPA) provides California residents
      with certain privacy rights concerning their Personal Data. As a resident
      of California, you have the following rights:
    </p>
    <ul>
      <li>
        Right to Know: You have the right to request information about the
        categories of Personal Data we collect, the sources from which we
        collect it, the purposes for which we use it, and the third parties with
        whom we share.
      </li>
      <li>
        Right to Delete: You can request the deletion of your Personal Data that
        we have collected and retained, subject to certain exceptions.
      </li>
      <li>
        Right to Opt-Out: You have the right to opt-out of the sale of your
        Personal Data to third parties.
      </li>
    </ul>
    <p>
      To exercise any of these rights, please contact us using the contact
      information provided in this Privacy Notice. We will respond to your
      request in accordance with the CCPA regulations.
    </p>
    <h3 className={styles.header}>SECURITY MEASURES IMPLEMENTED</h3>
    <p>
      To safeguard your Personal Data, we maintain robust security measures. As
      part of our commitment, we have implemented comprehensive technical,
      physical, and organizational safeguards to protect your data from
      unauthorized or accidental disclosure, alteration, destruction, misuse,
      theft, accidental loss, or unauthorized access. These measures are
      designed to ensure the utmost security and confidentiality of your
      Personal Data throughout its processing and storage.
    </p>

    <h3 className={styles.header}>COOKIES</h3>
    <p>
      A cookie is a small piece of data that the Website asks your browser to
      store on your device in order to remember information about you, such as
      your language preference or login information. We use the following
      cookies:
    </p>
    <ul>
      <li>
        <strong>Essential Cookies:</strong> These cookies are necessary for
        allowing users to navigate around our Website and access features. These
        cookies do not collect information about you that could be used for
        marketing purposes and do not remember your browsing history on the
        internet;
      </li>
      <li>
        <strong>Functional Cookies:</strong> These cookies allow the Website to
        offer enhanced functionality and personalization. They may be set by us
        or by third-party providers whose services we have included on our
        pages. If you do not allow these cookies, some or all of these services
        may not work properly;
      </li>
      <li>
        <strong>Performance Cookies:</strong> We use such analytics tools as
        Plausible and Hotjar. Each of these tools help us to gather information
        about how visitors use the Website. These cookies collect information in
        aggregate form to provide us with insight into how our Website is being
        used. More information about Hotjar cookies you can find{' '}
        <a
          href="https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </li>
    </ul>
    <p>
      Most modern web browsers offer an option to view the cookies stored on
      your device, and also permit you to customize your preferences by
      accessing their cookie settings.
    </p>
    <p>
      Here is a list of links which will help you to understand how to manage
      your cookies preferences:
    </p>
    <ul>
      <li>
        <a
          href="https://www.aboutcookies.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Information about cookies in general;
        </a>
      </li>
      <li>
        <a
          href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to manage your cookies in Mozilla Firefox;
        </a>
      </li>
      <li>
        <a
          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to manage your cookies in Chrome;
        </a>
      </li>
      <li>
        <a
          href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to manage your cookies in Safari;
        </a>
      </li>
      <li>
        <a
          href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to manage your cookies in Microsoft Edge;
        </a>
      </li>
      <li>
        <a
          href="https://help.opera.com/en/latest/web-preferences/"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to manage your cookies in Opera.
        </a>
      </li>
    </ul>

    <h3 className={styles.header}>THIRD-PARTY LINKS</h3>
    <p>
      The Website may contain the links to the websites which are owned and
      operated by third parties. We would like to clarify that these websites
      are beyond our control, and we assume no liability for the outcomes of
      your visit to these external sites. We highly recommend that you review
      the “Privacy Notice” or any other privacy documentation provided by these
      third-party services before disclosing any data to them. This
      precautionary measure will help you understand how your data will be
      handled and protected by those services.
    </p>

    <h3 className={styles.header}>USE BY CHILDREN</h3>
    <p>We do not knowingly collect any Personal Data connected to Children.</p>

    <h3 className={styles.header}>CHANGES TO THE PRIVACY NOTICE</h3>
    <p>
      This Notice may be changed from time to time due to the implementation of
      new technologies, laws’ requirements, or for other purposes. We will send
      notice to you if these changes are dramatic and where required by
      applicable laws, we will obtain your consent. Also, we encourage you to
      regularly review this Notice to check for any changes.
    </p>
  </div>
);

export default PrivacyNotice;
