import React, { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isImgMedia } from 'utils/img';

import styles from './Features.module.scss';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

const Features = ({ data, title }) => {
  const [active, setActive] = useState('0');
  const handleClick = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setActive(event.target.getAttribute('data-active'));
  }, []);

  const isImg = useCallback((media) => isImgMedia(media), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.sectionTitle}>{title}</div>
        <div className={styles.sectionContent}>
          <ul className={styles.list}>
            {data.map((item, index) => (
              <li
                key={index}
                className={classNames(styles.item, {
                  [styles.active]: active === index.toString(),
                })}
              >
                <span className={styles.indicator} />
                <a
                  className={styles.link}
                  data-active={index}
                  onClick={handleClick}
                >
                  {item.name}
                </a>
                <span className={styles.text}>{item.description}</span>
              </li>
            ))}
          </ul>
          <div className={styles.content}>
            {data.map((item, index) => (
              <div
                key={index}
                className={classNames(styles.pane, {
                  [styles.activePane]: active === index.toString(),
                })}
              >
                {isImg(item.media) && <img src={item.media} alt={item.name} />}
                {!isImg(item.media) && (
                  <ReactPlayer
                    url={item.media}
                    controls={false}
                    className={styles.video}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

Features.propTypes = propTypes;
export default Features;
