import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { useGetTournamentLeaderBoardQuery } from 'store/api/tournamentService/recipe.api';
import { userSelector } from 'store/slices/user';

import { formatDateTime, formatViewDate } from 'utils/date';
import { findPathPages } from 'utils/pages';

import Button from 'components/UI/Button/Button';

import { PAGE_PATH } from 'components/config/pages';
import { STATUSES } from 'components/config/tournaments';

import stylesLayout from 'components/pages/Tournaments/Tournaments.module.scss';

const propTypes = {
  item: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
    eventStatus: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    activeStatusStartTime: PropTypes.string.isRequired,
    activeStatusFinishTime: PropTypes.string.isRequired,
    eventPrizePool: PropTypes.string.isRequired,
    numberOfRewardRanks: PropTypes.number.isRequired,
    smallBannerUrl: PropTypes.string.isRequired,
  }).isRequired,
};

const Card = ({ item }) => {
  const dateFormatter =
    item.eventStatus === STATUSES.ended ? formatDateTime : formatViewDate;
  const userInfo = useSelector(userSelector.getInfo);
  const { data: leaderBoardData } = useGetTournamentLeaderBoardQuery(
    {
      event_id: item.eventId,
      user_id: userInfo?.id,
      size: 1,
    },
    {
      skip: !item || item.eventStatus === STATUSES.comingSoon,
    },
  );

  return (
    <Link
      className={stylesLayout.card}
      to={findPathPages(PAGE_PATH.TOURNAMENTS_ITEM).replace(
        ':item',
        item.eventId,
      )}
    >
      <img src={item.smallBannerUrl} alt={item.eventName} />
      <div className={stylesLayout.title}>{item.eventName}</div>
      <div className={stylesLayout.period}>
        {dateFormatter(new Date(item.activeStatusStartTime))}
        {' - '}
        {dateFormatter(new Date(item.activeStatusFinishTime))}
      </div>
      <div className={stylesLayout.info}>
        {item.eventStatus !== STATUSES.comingSoon && (
          <div className={stylesLayout.position}>
            <span>Position:</span>
            <span>
              {leaderBoardData?.currentPlayersEntry?.player?.rank
                ? `#${leaderBoardData?.currentPlayersEntry?.player?.rank}`
                : '-'}
            </span>
          </div>
        )}
        <div className={stylesLayout.prize}>
          <span>Prize pool:</span>
          <span>{item.eventPrizePool}</span>
        </div>
      </div>
      {item.eventStatus === STATUSES.ended && (
        <div className={stylesLayout.more}>
          <Button>Learn more</Button>
        </div>
      )}
    </Link>
  );
};

Card.propTypes = propTypes;
export default Card;
