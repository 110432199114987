import { useMemo } from 'react';

import { useGetRecommendedQuery } from 'store/api/configService/gameController';

import { findPathPages } from 'utils/pages';

import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Slider from 'components/Slider/Slider';

import { PAGE_PATH } from 'components/config/pages';
import { PROVIDERS } from 'config/organization';

const RecommendedGames = () => {
  const { data } = useGetRecommendedQuery();

  const sliderData = useMemo(
    () =>
      data?.map(({ regionId, name, type, gameCode, description, images }) => ({
        regionId,
        name,
        type,
        gameCode,
        description,
        sliderSmall: images?.sliderSmall,
        sliderLarge: images?.sliderLarge,
        icon: images?.icon,
        logo: images?.logo,
      })),
    [data],
  );

  if (!data?.length) return null;

  return (
    <HomeComponentsLayout
      title="Recommended Games"
      linkPath={findPathPages(PAGE_PATH.ALL_GAMES).replace(
        ':provider',
        PROVIDERS.evoplay,
      )}
      linkTitle="All Evoplay Games"
    >
      <Slider data={sliderData} type="vertical" />
    </HomeComponentsLayout>
  );
};

export default RecommendedGames;
