import { createSlice } from '@reduxjs/toolkit';

import { getStorageData, setStorageData } from 'utils/storage/sidebar';

const initialState = {
  visible: getStorageData().visible,
};

export const sidebarMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (result.type === 'sidebar/toggleVisible') {
      setStorageData({ visible: getState().sidebar.visible });
    }
    return result;
  };
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleVisible: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const sidebarSelector = {
  getVisible: (state) =>
    state.sidebar.visible === true || state.sidebar.visible === 'true',
};

export const { toggleVisible } = sidebarSlice.actions;
export default sidebarSlice.reducer;
