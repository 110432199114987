import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';

import styles from 'components/Layouts/HomeComponents/HomeComponentsLayout.module.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  styleWrapper: PropTypes.string,
  styleHeader: PropTypes.string,
};

const HomeComponentsLayout = ({
  title,
  linkPath,
  linkTitle,
  children,
  styleWrapper = styles.content,
  styleHeader = null,
}) => (
  <div className={styleWrapper}>
    <div className={classNames(styles.header, styleHeader)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.link}>
        <ArrowCircleLink title={linkTitle} path={linkPath} />
      </div>
    </div>
    {children}
  </div>
);

HomeComponentsLayout.propTypes = propTypes;
export default HomeComponentsLayout;
