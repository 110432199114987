import React from 'react';

import styles from './Copyright.module.scss';

const Copyright = () => (
  <div className={styles.wrapper}>
    Evoplay &#169; {new Date().getFullYear()}.
    <br />
    All rights reserved.
  </div>
);

export default Copyright;
