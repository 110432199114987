import { roadmapPath } from 'components/config/cloud';

export const pdfPath = {
  evoplay: `${roadmapPath}/Evoplay/Game+Releases+Calendar.pdf`,
  slotopia: `${roadmapPath}/Slotopia/Game+Releases+Calendar.pdf`,
};
export const xlsxPath = {
  evoplay: `${roadmapPath}/Evoplay/Games_Releases_Info.xlsx`,
  slotopia: `${roadmapPath}/Slotopia/Games_Releases_Info.xlsx`,
};
