export const ITALY_DOWNLOAD_MSG =
  'Need an Italian market branded report? Contact your Key Account Manager.';

export const Country = [
  'Bulgaria',
  'Croatia',
  'Italy',
  'Lithuania',
  'Malta (MGA)',
  'Portugal',
  'Romania',
];

export const getCountryMap = (country) =>
  country === 'Malta (MGA)' ? 'Malta' : country;

export const data = [
  {
    Game: 'Ace Round',
    'Marketing Release': '11.07.2017',
    GameCode: 'ace_round',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Animal Quest',
    'Marketing Release': '23.04.2020',
    GameCode: 'animal_quest',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Anubis’ Moon',
    'Marketing Release': '23.09.2021',
    GameCode: 'anubis_moon',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Basketball',
    'Marketing Release': '10.11.2016',
    GameCode: 'basketball',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Battle Tanks',
    'Marketing Release': '11.04.2018',
    GameCode: 'battle_tanks',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Blessed Flame',
    'Marketing Release': '04.10.2022',
    GameCode: 'blessed_flame',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Bloody Brilliant',
    'Marketing Release': '24.06.2021',
    GameCode: 'bloody_brilliant',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Bomb Squad',
    'Marketing Release': '22.01.2020',
    GameCode: 'bomb_squad',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Bonanza Wheel',
    'Marketing Release': '22.07.2021',
    GameCode: 'bonanza_wheel',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Book of Keno',
    'Marketing Release': '15.10.2020',
    GameCode: 'book_of_keno',
    Bulgaria: '',
    Croatia: '',
    Italy: '',
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Book of Rest',
    'Marketing Release': '12.12.2019',
    GameCode: 'book_of_rest',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Book of the Priestess',
    'Marketing Release': '07.02.2023',
    GameCode: 'book_of_the_priestess',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Book of the Priestess Bonus Buy',
    'Marketing Release': '23.03.2023',
    GameCode: 'book_of_the_priestess_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Brutal Santa',
    'Marketing Release': '18.11.2019',
    GameCode: 'brutal_santa',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Budai Reels',
    'Marketing Release': '25.01.2022',
    GameCode: 'budai_reels',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Budai Reels Bonus Buy',
    'Marketing Release': '22.02.2022',
    GameCode: 'budai_reels_bonus_buy',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Camino de Chili',
    'Marketing Release': '07.07.2022',
    GameCode: 'camino_de_chili',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Camino de Chili Bonus Buy',
    'Marketing Release': '16.08.2022',
    GameCode: 'camino_de_chili_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Candy Dreams',
    'Marketing Release': '24.01.2018',
    GameCode: 'candy_dreams',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Candy Dreams: Bingo',
    'Marketing Release': '14.02.2023',
    GameCode: 'bingo',
    Bulgaria: 1,
    Croatia: 1,
    Italy: '',
    Lithuania: '',
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Candy Dreams: Sweet Planet',
    'Marketing Release': '30.06.2022',
    GameCode: 'candy_dreams_sweet_planet',
    Bulgaria: 1,
    Croatia: '',
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Candy Dreams: Sweet Planet. Bonus Buy',
    'Marketing Release': '30.08.2022',
    GameCode: 'candy_dreams_sweet_planet_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Christmas Reach',
    'Marketing Release': '23.11.2021',
    GameCode: 'christmas_reach',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Courier Sweeper',
    'Marketing Release': '12.12.2019',
    GameCode: 'courier_sweeper',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Crown and Anchor',
    'Marketing Release': '02.04.2020',
    GameCode: 'crown_and_anchor',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Curse of the Pharaoh',
    'Marketing Release': '28.09.2021',
    GameCode: 'curse_of_the_pharaoh',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Cycle of Luck',
    'Marketing Release': '06.05.2021',
    GameCode: 'cycle_of_luck',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Cycle of Luck Bonus Buy',
    'Marketing Release': '05.10.2021',
    GameCode: 'cycle_of_luck_bonus_buy',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: "Dragon's Tavern",
    'Marketing Release': '14.12.2021',
    GameCode: 'dragons_tavern',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'E.T. Races',
    'Marketing Release': '24.01.2019',
    GameCode: 'e_t_races',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Egypt Gods',
    'Marketing Release': '24.10.2016',
    GameCode: 'egypt_gods',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: "Ellen's Fortune",
    'Marketing Release': '01.03.2021',
    GameCode: 'ellens_fortune',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Elven Princesses',
    'Marketing Release': '11.07.2017',
    GameCode: 'elven_princesses',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Epic Gladiators',
    'Marketing Release': '12.12.2017',
    GameCode: 'epic_gladiators',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Epic Legends',
    'Marketing Release': '25.03.2021',
    GameCode: 'epic_legends',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Exploding Fruits',
    'Marketing Release': '25.06.2020',
    GameCode: 'exploding_fruits',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Food Feast',
    'Marketing Release': '12.04.2021',
    GameCode: 'food_feast',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Football Scratch',
    'Marketing Release': '28.10.2022',
    GameCode: 'football_scratch',
    Bulgaria: 1,
    Croatia: 1,
    Italy: '',
    Lithuania: '',
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Forest Dreams',
    'Marketing Release': '20.08.2020',
    GameCode: 'forest_dreams',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Forgotten Fable',
    'Marketing Release': '10.09.2020',
    GameCode: 'forgotten_fable',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Fruit Nova',
    'Marketing Release': '12.11.2020',
    GameCode: 'fruit_nova',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Super Nova',
    'Marketing Release': '28.01.2021',
    GameCode: 'fruit_super_nova',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Super Nova 30',
    'Marketing Release': '02.09.2021',
    GameCode: 'fruit_super_nova_30',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Super Nova 40',
    'Marketing Release': '08.07.2021',
    GameCode: 'fruit_super_nova_40',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Super Nova 60',
    'Marketing Release': '12.10.2021',
    GameCode: 'fruit_super_nova_60',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Super Nova 100',
    'Marketing Release': '16.11.2021',
    GameCode: 'fruit_super_nova_100',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Fruit Burst',
    'Marketing Release': '08.09.2017',
    GameCode: 'fruit_burst',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Funny Hunting',
    'Marketing Release': '02.03.2023',
    GameCode: 'funny_hunting',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Gangster Night',
    'Marketing Release': '08.06.2021',
    GameCode: 'gangster_night',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Gold of Sirens',
    'Marketing Release': '17.06.2021',
    GameCode: 'gold_of_sirens',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Gold of Sirens Bonus Buy',
    'Marketing Release': '19.10.2021',
    GameCode: 'gold_of_sirens_bonus_buy',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Hot Rio Nights',
    'Marketing Release': '26.01.2023',
    GameCode: 'hot_rio_nights',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Hot Rio Nights Bonus Buy',
    'Marketing Release': '09.02.2023',
    GameCode: 'hot_rio_nights_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: '',
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Hot Triple Sevens',
    'Marketing Release': '27.12.2019',
    GameCode: 'hot_triple_sevens',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Hot Triple Sevens Special',
    'Marketing Release': '18.11.2021',
    GameCode: 'hot_triple_sevens_special',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Hot Volcano',
    'Marketing Release': '23.08.2022',
    GameCode: 'hot_volcano',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Ice Mania',
    'Marketing Release': '22.10.2020',
    GameCode: 'ice_mania',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: "Indiana's Quest",
    'Marketing Release': '17.05.2017',
    GameCode: 'indianas_quest',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Inner Fire',
    'Marketing Release': '06.12.2022',
    GameCode: 'inner_fire',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Inner Fire Bonus Buy',
    'Marketing Release': '17.01.2023',
    GameCode: 'inner_fire_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Irish Reels',
    'Marketing Release': '18.02.2020',
    GameCode: 'irish_reels',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Jolly Treasures',
    'Marketing Release': '17.09.2020',
    GameCode: 'jolly_treasures',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Juicy Gems',
    'Marketing Release': '12.05.2022',
    GameCode: 'juicy_gems',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Juicy Gems Bonus Buy',
    'Marketing Release': '07.06.2022',
    GameCode: 'juicy_gems_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Legends of Ra',
    'Marketing Release': '13.10.2017',
    GameCode: 'legend_of_ra',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Lucky Card',
    'Marketing Release': '15.03.2022',
    GameCode: 'lucky_card',
    Bulgaria: 1,
    Croatia: 1,
    Italy: '',
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Lucky Girls',
    'Marketing Release': '21.09.2018',
    GameCode: 'lucky_girls',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Magic Wheel',
    'Marketing Release': '03.01.2020',
    GameCode: 'magic_wheel',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Massive Luck',
    'Marketing Release': '15.09.2022',
    GameCode: 'massive_luck',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Mega Greatest Catch',
    'Marketing Release': '06.09.2022',
    GameCode: 'mega_greatest_catch',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Mehen',
    'Marketing Release': '10.02.2021',
    GameCode: 'mehen',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Midnight Show',
    'Marketing Release': '04.06.2020',
    GameCode: 'midnight_show',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Mine Field',
    'Marketing Release': '24.03.2020',
    GameCode: 'mine_field',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Money Minter',
    'Marketing Release': '19.07.2022',
    GameCode: 'money_minter',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Money Minter Bonus Buy',
    'Marketing Release': '20.09.2022',
    GameCode: 'money_minter_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Night of the Living Tales',
    'Marketing Release': '18.09.2020',
    GameCode: 'living_tales',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: "Patrick's Magic Field",
    'Marketing Release': '10.03.2022',
    GameCode: 'patricks_magic_field',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Penalty Shoot-out',
    'Marketing Release': '26.05.2020',
    GameCode: 'penalty_shoot_out_street',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Redrose Sanctuary',
    'Marketing Release': '11.10.2022',
    GameCode: 'redrose_sanctuary',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Rich Reels',
    'Marketing Release': '18.02.2020',
    GameCode: 'rich_reels',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Rise of Horus',
    'Marketing Release': '12.03.2020',
    GameCode: 'rise_of_horus',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Robin Hood',
    'Marketing Release': '15.11.2016',
    GameCode: 'robin_hood',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Rocket Stars',
    'Marketing Release': '29.04.2020',
    GameCode: 'rocket_stars',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Roll the Dice',
    'Marketing Release': '15.02.2018',
    GameCode: 'roll_dice',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Runes of Destiny',
    'Marketing Release': '24.02.2021',
    GameCode: 'runes_of_destiny',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Scratch Match',
    'Marketing Release': '27.12.2019',
    GameCode: 'scratch_match',
    Bulgaria: '',
    Croatia: '',
    Italy: '',
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Shadow of Luxor',
    'Marketing Release': '19.08.2021',
    GameCode: 'shadow_of_luxor',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Sold it',
    'Marketing Release': '20.12.2022',
    GameCode: 'sold_it',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Sold it Bonus Buy',
    'Marketing Release': '19.01.2023',
    GameCode: 'sold_it_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Sweet Sugar',
    'Marketing Release': '01.04.2021',
    GameCode: 'sweet_sugar',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Temple of Dead',
    'Marketing Release': '14.01.2021',
    GameCode: 'temple_of_dead',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Temple of Thunder',
    'Marketing Release': '29.03.2022',
    GameCode: 'temple_of_thunder',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'The Greatest Catch',
    'Marketing Release': '19.04.2022',
    GameCode: 'the_greatest_catch',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'The Greatest Catch Bonus Buy',
    'Marketing Release': '06.05.2022',
    GameCode: 'the_greatest_catch_bonus_buy',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Thimbles',
    'Marketing Release': '05.03.2018',
    GameCode: 'thimbles',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: '',
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Treasure-snipes',
    'Marketing Release': '23.02.2023',
    GameCode: 'treasure_snipes',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Tree of Light',
    'Marketing Release': '03.12.2020',
    GameCode: 'tree_of_light',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Triple Chili',
    'Marketing Release': '30.09.2021',
    GameCode: 'triple_chili',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Unlimited Wishes',
    'Marketing Release': '18.03.2021',
    GameCode: 'unlimited_wishes',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Valley of Dreams',
    'Marketing Release': '29.10.2020',
    GameCode: 'valley_of_dreams',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: 1,
    Romania: 1,
  },
  {
    Game: 'Vegas Nights',
    'Marketing Release': '02.12.2016',
    GameCode: 'vegas_nights',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Western Reels',
    'Marketing Release': '06.04.2020',
    GameCode: 'western_reels',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wheel of Time',
    'Marketing Release': '08.09.2020',
    GameCode: 'wheel_of_time',
    Bulgaria: '',
    Croatia: '',
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: '',
  },
  {
    Game: 'Wild Bullets',
    'Marketing Release': '17.12.2020',
    GameCode: 'wild_bullets',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wild Overlords',
    'Marketing Release': '11.01.2022',
    GameCode: 'wild_overlords',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: 1,
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wild Overlords Bonus Buy',
    'Marketing Release': '12.04.2022',
    GameCode: 'wild_overlords_bonus_buy',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wolf Hiding',
    'Marketing Release': '04.08.2022',
    GameCode: 'wolf_hiding',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wonder Farm',
    'Marketing Release': '30.03.2023',
    GameCode: 'wonder_farm',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
  {
    Game: 'Wonder Farm Bonus Buy',
    'Marketing Release': '11.04.2023',
    GameCode: 'wonder_farm_b_b',
    Bulgaria: 1,
    Croatia: 1,
    Italy: 3,
    Lithuania: 1,
    Malta: '',
    Portugal: '',
    Romania: 1,
  },
];
