import React from 'react';

import useCountdown from 'hooks/countdown';
import PropTypes from 'prop-types';

import { formatDateTime } from 'utils/date';

import { STATUSES, getCategory, getType } from 'components/config/tournaments';

import styles from 'components/TournamentPopup/Banner/Banner.module.scss';

const propTypes = {
  item: PropTypes.shape({
    eventStatus: PropTypes.string.isRequired,
    activeStatusFinishTime: PropTypes.string.isRequired,
    activeStatusStartTime: PropTypes.string.isRequired,
    eventPrizePool: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    smallBannerUrl: PropTypes.string.isRequired,
  }).isRequired,
};

const Banner = ({ item }) => {
  const timeLeft = useCountdown(
    item.eventStatus === STATUSES.running
      ? new Date(item.activeStatusFinishTime)
      : new Date(item.activeStatusStartTime),
  );

  const categoryConfig = getCategory(getType[item.eventStatus]);

  return (
    <div className={styles.wrapper}>
      <img src={item.smallBannerUrl} className={styles.img} alt="PromoBanner" />
      <div className={styles.content}>
        {categoryConfig && (
          <div className={styles.tag}>
            <div
              className={styles.tagContent}
              style={{ color: categoryConfig.color }}
            >
              {categoryConfig.title}
            </div>
          </div>
        )}
        <div className={styles.title}>{item.eventName}</div>
        <div className={styles.period}>
          {formatDateTime(new Date(item.activeStatusStartTime))}
          {' - '}
          {formatDateTime(new Date(item.activeStatusFinishTime))}
        </div>
        <div className={styles.info}>
          <div className={styles.infoBlock}>
            <span className={styles.infoLabel}>
              {item.eventStatus === STATUSES.running ? 'Ends in' : 'Starts in'}:
            </span>
            <span className={styles.infoValue}>
              {timeLeft
                ? `${timeLeft.days}d : ${timeLeft.hours}h : ${timeLeft.minutes}m : ${timeLeft.seconds}s`
                : '-'}
            </span>
          </div>
          <div className={styles.infoBlock}>
            <span className={styles.infoLabel}>Prize pool:</span>
            <span className={styles.infoValue}>{item.eventPrizePool}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = propTypes;
export default Banner;
