import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useS3Bucket } from 'hooks/s3';

import { show } from 'store/slices/alert';
import { setDialogData } from 'store/slices/downloadMaterials';

import {
  generateGameMaterials,
  materialsConfig,
} from 'utils/generateMaterials';

import { CircularProgress } from '@mui/material';

import ContentLayout from 'components/Layouts/Content/ContentLayout';

import { gamePath } from 'components/config/cloud';

import styles from './DownloadGameMaterials.module.scss';

const DownloadGameMaterials = () => {
  const dispatch = useDispatch();

  const { gameCode } = useParams();

  const { fetchData, loading } = useS3Bucket({
    prefixes: `Games/${gameCode}/`,
    formatItems: generateGameMaterials,
  });

  const getMaterials = useCallback(async () => {
    const materials = await fetchData();

    dispatch(
      Object.keys(materials).length
        ? setDialogData({
            type: 'Game',
            materials,
            structure: materialsConfig.game.structure,
            folderPath: gamePath(gameCode),
            downloadFileName: gameCode,
            languageLabel: 'Languages',
            gamesData: [],
          })
        : show({
            type: 'error',
            text: 'Data for this game is unavailable. Please check the URL for the correct game code.',
            isAutoHide: false,
          }),
    );
  }, [gameCode]);

  useEffect(() => {
    getMaterials();
  }, []);

  return (
    <ContentLayout>
      <div className={styles.wrapper}>{loading && <CircularProgress />}</div>
    </ContentLayout>
  );
};

export default DownloadGameMaterials;
