import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

const ExpandMore = createSvgIcon(
  <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />,
  'ExpandMore',
);

export default ExpandMore;
