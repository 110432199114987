import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import classNames from 'classnames';

import { findPathPages } from 'utils/pages';
import {
  filterByMarket,
  getComingSoon,
  getEnded,
  getMarkets,
  getRunning,
} from 'utils/promotions';

import ArrowCircleLink from 'components/ArrowCircleLink/ArrowCircleLink';
import Select from 'components/UI/Select/Select';
import NotFound from 'components/pages/NotFound/NotFound';
import Card from 'components/pages/Promotions/Card/Card';

import { PAGE_PATH } from 'components/config/pages';
import { data } from 'components/config/promotions';

import { ReactComponent as PromoIcon } from 'images/Icons/Promo.svg';

import styles from 'components/pages/Promotions/Category/Category.module.scss';
import stylesLayout from 'components/pages/Promotions/Promotions.module.scss';

export const Category = () => {
  const [items, setItems] = useState([]);

  const { pathname } = useLocation();
  const { provider, category } = useParams();

  const fabrics = {
    comingSoon: getComingSoon,
    running: getRunning,
    ended: getEnded,
  };

  useEffect(() => {
    setItems(fabrics[category] ? fabrics[category](data[provider]) : null);
  }, [pathname]);

  const filterData = useCallback((category, market) => {
    if (market === 'All') {
      setItems(fabrics[category](data[provider]));
    } else {
      setItems(filterByMarket(fabrics[category](data[provider]), market));
    }
  }, []);

  const formatCategory = category === 'comingSoon' ? 'coming soon' : category;

  if (items === null || !fabrics[category]) {
    return <NotFound />;
  }
  if (!items.length) {
    return null;
  }

  return (
    <div className={stylesLayout.wrapper}>
      <div className={styles.back}>
        <ArrowCircleLink
          path={findPathPages(PAGE_PATH.PROMOTIONS).replace(
            ':provider',
            provider,
          )}
          title="Go to all promotions"
          direction="left"
        />
      </div>
      <div className={stylesLayout.comingSoon}>
        <div className={stylesLayout.title}>
          <div>
            <span>{formatCategory}</span>
            <PromoIcon />
            <span>{items.length}</span>
          </div>
          <div>
            <Select
              handleSelect={(_, value) => filterData(category, value)}
              title="Market"
              label="market"
              idElement={category}
              items={['All', ...getMarkets(fabrics[category](data[provider]))]}
              width={205}
            />
          </div>
        </div>
        <div className={classNames(stylesLayout.content, styles.content)}>
          {items.map((item, index) => (
            <Card
              key={index}
              item={item}
              category={category}
              provider={provider}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
