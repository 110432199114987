import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetPostsQuery } from 'store/api/gameService/recipe.api';
import { viewModeSelector } from 'store/slices/viewMode';

import { findPathPages } from 'utils/pages';
import { VIEW_MODE } from 'utils/viewMode';

import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Item from 'components/pages/Home/News/Item/Item';
import Main from 'components/pages/Home/News/Main/Main';

import { categories } from 'components/config/newsAndUpdates';
import { PAGE_PATH } from 'components/config/pages';

import styles from './News.module.scss';

const itemsSizes = {
  [VIEW_MODE.SIMPLE]: 4,
  [VIEW_MODE.WIDE]: 5,
};

const News = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const { data } = useGetPostsQuery({
    size: itemsSizes[viewMode],
  });

  const news = useMemo(
    () =>
      data?.content?.map((post) => ({
        ...post,
        category:
          post.postType === categories.news.postType ? 'news' : 'updates',
      })),
    [data],
  );

  const mainItem = useMemo(() => news?.[0], [news]);

  return (
    <HomeComponentsLayout
      linkPath={findPathPages(PAGE_PATH.NEWS)}
      title="News and Updates"
      linkTitle="All News"
    >
      <AnimationLayout animation="fadeInUp">
        <div className={styles.wrapper}>
          {mainItem && <Main news={mainItem} />}
          <div className={styles.newsItems}>
            {news?.slice(1).map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </div>
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default News;
