import { isRejectedWithValue } from '@reduxjs/toolkit';

import { show } from 'store/slices/alert';

import { doLogout } from 'services/UserService';

export const rtkQueryErrorLogger =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      const {
        payload: { data, status, error },
        meta,
      } = action;

      if ([403, 401].includes(status)) {
        doLogout();
        return next(action);
      }

      console.warn('We got a rejected action!', action);

      let errorMessage =
        'Oops! Something went wrong. Please try refreshing the page, or contact support if the problem persists.';

      if (error) {
        errorMessage = error;
      } else if (data === 'System not found') {
        errorMessage = `System not found: ${meta?.baseQueryMeta?.request?.url}`;
      } else if (data?.errorMessage) {
        errorMessage = data.errorMessage;
      } else if (data?.error) {
        errorMessage = data.error;
      } else if (data?.detail) {
        errorMessage = data.detail;
      }

      dispatch(
        show({
          name: meta?.requestId,
          type: 'error',
          text: errorMessage,
        }),
      );
    }

    return next(action);
  };
