import React from 'react';

import Banner from 'components/pages/Home/Banner/Banner';
import ComingSoon from 'components/pages/Home/ComingSoon/ComingSoon';
import LatestReleases from 'components/pages/Home/LatestReleases/LatestReleases';
import News from 'components/pages/Home/News/News';
import RecommendedGames from 'components/pages/Home/RecommendedGames/RecommendedGames';
import Tournament from 'components/pages/Home/Tournament/Tournament';
import UpcomingPromotions from 'components/pages/Home/UpcomingPromotions/UpcomingPromotions';

import styles from './Home.module.scss';

const Home = () => (
  <div className={styles.wrapper}>
    <Banner />
    <Tournament />
    <RecommendedGames />
    <LatestReleases />
    <ComingSoon />
    <UpcomingPromotions />
    <News />
  </div>
);

export default Home;
