import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { viewModeSelector } from 'store/slices/viewMode';

import ActiveSlide from 'components/Slider/ActiveSlide/ActiveSlide';
import Thumbnail from 'components/Slider/Thumbnail/Thumbnail';

import styles from './Slider.module.scss';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      gameCode: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
};

const findNexSlide = (data, activeSlide) => {
  let findItem = data[0];
  const findActiveIndex = data.findIndex(
    (item) => item.gameCode === activeSlide,
  );
  if (findActiveIndex < data.length - 1 && data[findActiveIndex + 1]) {
    findItem = data[findActiveIndex + 1];
  }

  return findItem.gameCode;
};

const Slider = ({ data, type }) => {
  const [activeSlide, setActiveSlide] = useState(data[0].gameCode);
  const [pause, setPause] = useState(false);
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const handleClickItem = useCallback((item) => {
    setActiveSlide(item.gameCode);
    setPause(true);
  }, []);
  const handlePause = useCallback(() => setPause(true), []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pause) {
        setActiveSlide(findNexSlide(data, activeSlide));
      }
    }, 7000);
    return () => clearInterval(interval);
  }, [activeSlide, data, pause]);

  return (
    <div className={classNames(styles.wrapper, styles[type])}>
      <div>
        {data.map((item) => (
          <ActiveSlide
            key={`ActiveSlide-${item.gameCode}`}
            item={item}
            handleClick={handlePause}
            viewMode={viewMode}
            type={type}
            isActive={activeSlide === item.gameCode}
          />
        ))}
      </div>
      {data.length > 1 && (
        <div className={styles.thumbnails}>
          {data.map((item) => (
            <Thumbnail
              key={`Thumbnail-${item.gameCode}`}
              isActive={item.gameCode === activeSlide}
              item={item}
              handleClick={handleClickItem}
              type={type}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Slider.propTypes = propTypes;

export default Slider;
