import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { allGamesSelector, toggleVisible } from 'store/slices/allGames';

import styles from './ToggleFilters.module.scss';

const propTypes = {
  title: PropTypes.string,
};

const ToggleFilters = ({ title = null }) => {
  const dispatch = useDispatch();
  const isVisible = useSelector(allGamesSelector.getVisible);

  const handleClick = useCallback(() => {
    dispatch(toggleVisible(!isVisible));
  }, [isVisible]);

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.toggle}>
        <div
          className={classNames({
            [styles.down]: !isVisible,
            [styles.up]: isVisible,
          })}
        ></div>
      </div>
    </div>
  );
};

ToggleFilters.propTypes = propTypes;
export default ToggleFilters;
