import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/slices/user';

import { EMAILS } from 'config/organization';

const useAccessView = () => {
  const userInfo = useSelector(userSelector.getInfo);

  return useMemo(
    () => userInfo?.email?.indexOf(EMAILS.tech) === -1,
    [userInfo],
  );
};

export default useAccessView;
