import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alertSelector, hide } from 'store/slices/alert';

import { AlertTitle, Alert as MuiAlert } from '@mui/material';

import styles from './Alert.module.scss';

const Alert = () => {
  const dispatch = useDispatch();
  const { items, isAutoHide } = useSelector(alertSelector.getData);

  const prepareTitle = useCallback(
    ({ title, type }) => {
      const text = title ? title : type;
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    [items],
  );

  useEffect(() => {
    if (Object.keys(items).length && isAutoHide) {
      setTimeout(() => dispatch(hide()), 5000);
    }
  }, [items]);

  if (!Object.keys(items).length) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {Object.keys(items).map((key) => (
        <MuiAlert
          key={key}
          severity={items[key].type}
          onClose={() => dispatch(hide(key))}
        >
          <AlertTitle>{prepareTitle(items[key])}</AlertTitle>
          <div dangerouslySetInnerHTML={{ __html: items[key].text }} />
        </MuiAlert>
      ))}
    </div>
  );
};
export default Alert;
