// import { CRYPTO, CUSTOM, WORD } from 'components/config/currency';
import { Languages } from 'components/config/languages';

export const prepareMaterials = (structure) =>
  Array.isArray(structure)
    ? structure
    : Object.keys(structure).reduce(
        (acc, row) => ({ ...acc, [row]: structure[row].items }),
        {},
      );

export const materialsStructure = {
  game_data: {
    category: 'Game Data',
  },
  product_sheets: {
    file: 'Product_sheet.pdf',
    category: 'Product Sheet',
  },
  'Promo Pack': {
    file: 'Promo Pack.zip',
    category: 'Promotion Pack',
    expand: 'Files',
    open: true,
    subCategory: {
      Icons: {
        file: 'Icons.zip',
        category: 'Icons',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        items: {
          JPG: [
            '160x160',
            '183x125',
            '210x210',
            '242x181',
            '242x182',
            '250x157',
            '253x228',
            '287x193',
            '288x375',
            '294x226',
            '300x225',
            '301x180',
            '315x250',
            '328x192',
            '354x210',
            '360x360',
            '383x200',
            '400x400',
            '440x310',
            '446x186',
            '446x186',
            '450x330',
            '720x340',
            '945x370',
            '1000x700',
            '1800x400',
            '1920x250',
          ],
          PSD: [
            '160x160',
            '183x125',
            '210x210',
            '242x181',
            '242x182',
            '250x157',
            '253x228',
            '287x193',
            '288x375',
            '294x226',
            '300x225',
            '301x180',
            '315x250',
            '328x192',
            '354x210',
            '360x360',
            '383x200',
            '400x400',
            '440x310',
            '446x186',
            '446x186',
            '450x330',
            '720x340',
            '945x370',
            '1000x700',
            '1800x400',
            '1920x250',
          ],
        },
      },
      Media: {
        file: 'Media.zip',
        category: 'Media',
        expand: 'Sizes',
        countColumns: 4,
        pack: ['JPG', 'PSD'],
        items: {
          JPG: [
            '63x64',
            '152x152',
            '270x270',
            '300x650',
            '301x180',
            '580x580',
            '1080x1080',
            '1080x1092',
            '1200x900',
            '1280x720_bg',
            '1920x1080_bg',
            '1920x1080',
          ],
          PSD: [
            '63x64',
            '152x152',
            '270x270',
            '300x650',
            '301x180',
            '580x580',
            '1080x1080',
            '1080x1092',
            '1200x900',
            '1280x720_bg',
            '1920x1080_bg',
            '1920x1080',
          ],
        },
      },
      Assets: {
        file: 'Assets.zip',
        category: 'Assets',
        expand: 'Files',
        countColumns: 4,
        items: ['Background', 'Game Elements', 'Logo', 'Other'],
      },
      Video: {
        file: 'Video.zip',
        category: 'Video',
        expand: 'Sizes',
        countColumns: 5,
        pack: ['MP4'],
        items: {
          MP4: ['1080x1080', '1080x1350', '1080x1920', '1200x630', '1920x1080'],
        },
      },
    },
  },
  // certificates: {
  //   file: 'Certificates.zip',
  //   category: 'Certificates',
  //   expand: 'Jurisdictions',
  //   countColumns: 5,
  //   subCategory: [
  //     'Bulgaria',
  //     'Croatia',
  //     'Lithuania',
  //     'Malta',
  //     'Portugal',
  //     'Romania',
  //   ],
  // },
  // currencies: {
  //   category: 'Bets in currencies (Max/Min/Default)',
  //   expand: 'Currencies',
  //   subCategory: {
  //     'World currencies': {
  //       category: 'World currencies',
  //       isAutocomplete: true,
  //       countColumns: 2,
  //       expand: ' ',
  //       items: WORD,
  //       component: 'Currency',
  //     },
  //     Custom: {
  //       category: 'Custom',
  //       countColumns: 2,
  //       expand: ' ',
  //       items: CUSTOM,
  //       component: 'Currency',
  //     },
  //     Crypto: {
  //       category: 'Crypto',
  //       isAutocomplete: true,
  //       countColumns: 2,
  //       expand: ' ',
  //       items: CRYPTO,
  //       component: 'Currency',
  //     },
  //   },
  // },
  rules: {
    category: 'Rules',
    expand: 'Languages',
    subCategory: Object.keys(Languages),
  },
};
