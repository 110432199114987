import React from 'react';
import { useSelector } from 'react-redux';

import { viewModeSelector } from 'store/slices/viewMode';

import { dateStringToDate } from 'utils/date';
import { findPathPages } from 'utils/pages';
import { getComingSoon, getRunning } from 'utils/promotions';
import { VIEW_MODE } from 'utils/viewMode';

import AnimationLayout from 'components/Layouts/Animation/AnimationLayout';
import HomeComponentsLayout from 'components/Layouts/HomeComponents/HomeComponentsLayout';
import Slider from 'components/Slider/Slider';

import { PAGE_PATH } from 'components/config/pages';
import { data } from 'components/config/promotions';

import styles from './UpcomingPromotions.module.scss';

const UpcomingPromotions = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);
  const countSize = viewMode === VIEW_MODE.SIMPLE ? 3 : 4;
  const upcomingData = getRunning(data.evoplay)
    .concat(getComingSoon(data.evoplay))
    .sort(
      (a, b) =>
        dateStringToDate(a.period[0].split(' ')[0]) -
        dateStringToDate(b.period[0].split(' ')[0]),
    )
    .slice(0, countSize)
    .map((item) => ({
      ...item,
      releaseDate: `${item.period[0].split(' ')[0]} - ${
        item.period[1].split(' ')[0]
      }`,
      sliderSmall: `${item.folderPath}slider_small.webm`,
      sliderLarge: `${item.folderPath}slider_large.webm`,
      icon: `${item.folderPath}slider_thumb.jpg`,
      gameCode: item.title,
      name: item.title,
      promoTags: item.tags,
      link: findPathPages(PAGE_PATH.PROMOTIONS_ITEM)
        .replace(':provider', 'evoplay')
        .replace(':category', 'comingSoon')
        .replace(':item', item.title),
    }));

  if (!upcomingData.length) {
    return null;
  }

  return (
    <HomeComponentsLayout
      title="Active and Upcoming Promotions"
      linkPath={findPathPages(PAGE_PATH.PROMOTIONS).replace(
        ':provider',
        'evoplay',
      )}
      linkTitle="All Evoplay Promotions"
      styleWrapper={styles.wrapper}
      styleHeader={styles.header}
    >
      <AnimationLayout animation="fadeInUp">
        <div className={styles.content}>
          <Slider data={upcomingData} type="horizontal" />
        </div>
      </AnimationLayout>
    </HomeComponentsLayout>
  );
};

export default UpcomingPromotions;
