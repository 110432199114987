import { createSlice } from '@reduxjs/toolkit';

import { getStorageData, setStorageData } from 'utils/storage/allGames';

import { Sort } from 'components/config/sort';

export const initialState = {
  selectedItems: [],
  filters: {},
  sortBy: Sort[0],
  download: {
    wholeCategory: [],
    category: {},
  },
  error: [],
  process: false,
  visible: getStorageData().visible,
  viewType: getStorageData().viewType,
  search: '',
};

export const allGamesMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (result.type === 'allGames/viewType') {
      setStorageData({ viewType: getState().allGames.viewType });
    }
    if (result.type === 'allGames/toggleVisible') {
      setStorageData({ visible: getState().allGames.visible });
    }
    return result;
  };
};

const allGamesSlice = createSlice({
  name: 'allGames',
  initialState,
  reducers: {
    viewType: (state, action) => {
      state.viewType = action.payload;
    },
    selectItem: (state, action) => {
      const selectedItems = new Set(state.selectedItems);
      if (selectedItems.has(action.payload)) {
        selectedItems.delete(action.payload);
      } else {
        selectedItems.add(action.payload);
      }
      state.selectedItems = Array.from(selectedItems);
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    clearSelectedItems: (state) => {
      state.selectedItems = initialState.selectedItems;
    },
    setFilters: (state, action) => {
      if (action.payload.value.length) {
        state.filters[action.payload.label] = action.payload.value;
      } else {
        delete state.filters[action.payload.label];
      }
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
    sortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    toggleVisible: (state, action) => {
      state.visible = action.payload;
    },
    clearDownload: (state) => {
      state.process = false;
      state.download = initialState.download;
      state.error = [];
    },
    setDownload: (state, action) => {
      const { wholeCategory, pack, category, row, label, el } = action.payload;

      if (wholeCategory) {
        const wholeCategorySet = new Set(state.download.wholeCategory);
        if (wholeCategorySet.has(wholeCategory)) {
          wholeCategorySet.delete(wholeCategory);
        } else {
          wholeCategorySet.add(wholeCategory);
        }

        state.download.wholeCategory = Array.from(wholeCategorySet);

        if (state.download.category[wholeCategory]) {
          state.download.category[wholeCategory] = {};
        }
        return;
      }

      if (category && !state.download.category[category]) {
        state.download.category[category] = {};
      }
      if (row && !state.download.category[category][row]) {
        state.download.category[category][row] = {};
      }

      if (pack) {
        if (state.download.category[category][row].pack === pack) {
          state.download.category[category][row].pack = '';
        } else {
          if (pack === 'All') {
            state.download.category[category][row] = { pack };
          } else {
            state.download.category[category][row].pack = pack;
          }
        }

        const findEl = Object.keys(state.download.category[category][row]).find(
          (item) =>
            state.download.category[category][row].pack.indexOf(item) !== -1,
        );
        if (findEl) {
          state.download.category[category][row][findEl] = [];
        }
      }

      if (label) {
        if (
          state.download.category[category][row].pack?.indexOf(el) !== -1 ||
          state.download.category[category][row].pack === 'All'
        ) {
          state.download.category[category][row].pack = '';
        }

        const elementLabel = new Set(
          state.download.category[category][row][el] || [],
        );
        if (elementLabel.has(label)) {
          elementLabel.delete(label);
        } else {
          elementLabel.add(label);
        }

        state.download.category[category][row][el] = Array.from(elementLabel);
      }

      if (el && !label) {
        const elSet = new Set(
          state.download.category[category][row]?.items || [],
        );
        if (elSet.has(el)) {
          elSet.delete(el);
        } else {
          elSet.add(el);
        }

        state.download.category[category][row].items = Array.from(elSet);
        state.download.category[category][row].pack = '';
      }

      const wholeCategorySet = new Set(state.download.wholeCategory);
      wholeCategorySet.delete(category);
      state.download.wholeCategory = Array.from(wholeCategorySet);
    },
    download: (state) => {
      state.process = true;
      state.download = initialState.download;
      state.error = [];
    },
    search: (state, action) => {
      state.search = action.payload;
    },
  },
});

export const allGamesSelector = {
  getViewType: (state) => state.allGames.viewType,
  getSortBy: (state) => state.allGames.sortBy,
  getSelectedItems: (state) => state.allGames.selectedItems,
  getFilters: (state) => state.allGames.filters,
  getVisible: (state) =>
    state.allGames.visible === true || state.allGames.visible === 'true',
  getDownload: (state) => state.allGames.download,
  getProcess: (state) => state.allGames.process,
  getSearch: (state) => state.allGames.search,
};

export const {
  viewType,
  selectItem,
  setSelectedItems,
  clearSelectedItems,
  setFilters,
  sortBy,
  clearFilters,
  toggleVisible,
  setDownload,
  clearDownload,
  download,
  search,
} = allGamesSlice.actions;
export default allGamesSlice.reducer;
