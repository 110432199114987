import PropTypes from 'prop-types';

import { formatViewDate } from 'utils/date';

import ComingSoon from 'components/ComingSoon/ComingSoon';
import GameId from 'components/GameId/GameId';
import Type from 'components/GameItem/Type/Type';
import ItemLayout from 'components/Layouts/Roadmap/ItemLayout';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import styles from 'components/pages/Roadmap/Item/Item.module.scss';

const Item = ({
  regionId,
  gameCode,
  images,
  name,
  miscellaneous,
  rtp,
  volatility,
  paylines,
  monetaryProperties,
  releaseDate,
  type,
}) => (
  <ItemLayout gameCode={gameCode}>
    <div className={styles.imgWrapper}>
      {miscellaneous?.marker && <Type type={miscellaneous.marker} />}
      <img src={images?.icon ?? ComingSoonImg} alt={name} loading="lazy" />
    </div>
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{name}</span>
        </div>
        {releaseDate && (
          <div className={styles.date}>
            <span>{formatViewDate(new Date(releaseDate))}</span>
          </div>
        )}
        <div className={styles.categoryWrapper}>
          <span className={styles.category}>
            <span>{type}</span>
          </span>
          {!!regionId?.length && <GameId id={regionId} color="grey" />}
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.row}>
          <span>RTP, %:</span>
          <span>{rtp.length ? `${rtp.join('%, ')}%` : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Volatility:</span>
          <span>{volatility ? volatility : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Paylines:</span>
          <span>{paylines ? paylines : <ComingSoon />}</span>
        </div>
        <div className={styles.row}>
          <span>Max Multiplier:</span>
          <span>{monetaryProperties?.maxMultiplier ?? <ComingSoon />}</span>
        </div>
      </div>
    </div>
  </ItemLayout>
);

Item.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  gameCode: PropTypes.string,
  regionId: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      region: PropTypes.string,
    }),
  ),
  images: PropTypes.shape({
    icon: PropTypes.string,
  }),
  miscellaneous: PropTypes.shape({
    marker: PropTypes.string,
  }),
  rtp: PropTypes.arrayOf(PropTypes.number),
  volatility: PropTypes.string,
  paylines: PropTypes.string,
  monetaryProperties: PropTypes.shape({
    maxMultiplier: PropTypes.number,
  }),
  releaseDate: PropTypes.string,
};

export default Item;
