import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { numberFormat } from 'utils/number';

import Tooltip from 'components/UI/Tooltip/Tooltip';

import { Flags, getLanguages } from 'components/config/languages';

import styles from 'components/pages/Promotions/Item/Item.module.scss';

const propTypes = {
  prizePool: PropTypes.number,
  market: PropTypes.arrayOf(PropTypes.string),
  mechanic: PropTypes.string,
  minBet: PropTypes.number,
  languages: PropTypes.arrayOf(PropTypes.string),
};

const TechnicalInformation = ({
  prizePool,
  market,
  mechanic,
  minBet,
  languages,
}) => (
  <div className={styles.gameInfo}>
    {prizePool && (
      <div className={styles.info}>
        <span>Prize pool:</span>
        <span>€{numberFormat(prizePool)}</span>
      </div>
    )}
    {market && (
      <div className={styles.info}>
        <span>Market:</span>
        <span>{market.join(', ')}</span>
      </div>
    )}
    {mechanic && (
      <div className={styles.info}>
        <span>Mechanic:</span>
        <span>{mechanic}</span>
      </div>
    )}
    {minBet && (
      <div className={styles.info}>
        <span>Minimum Bet:</span>
        <span>€{minBet}</span>
      </div>
    )}
    {languages && (
      <div
        className={classNames(styles.languages, {
          [styles.languagesRows]: languages.length > 8,
          [styles.languagesFlex]: languages.length < 3 && !(mechanic || minBet),
        })}
      >
        <div>Promo materials languages:</div>
        <div>
          {languages.map((language) => (
            <Tooltip title={getLanguages(language)} key={language}>
              {Flags[getLanguages(language)]?.({
                width: 24,
                height: 24,
              })}
            </Tooltip>
          ))}
        </div>
      </div>
    )}
  </div>
);

TechnicalInformation.propTypes = propTypes;
export default TechnicalInformation;
