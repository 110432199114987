import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { formatDate, weekdaysName } from 'utils/date';
import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import ComingSoonImg from 'images/Roadmap/ComingSoon.jpg';

import 'components/UI/Calendar/Event/Calendar.scss';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  currentDate: PropTypes.string.isRequired,
  handleSetMonth: PropTypes.func.isRequired,
};

const Calendar = ({ data, currentDate, handleSetMonth }) => {
  const navigate = useNavigate();
  const fp = useRef(null);

  const findElementByDate = useCallback(
    (date) => data.find((el) => el.releaseDate === date),
    [data],
  );

  const eventDates = useMemo(() => {
    const dataSet = new Set();
    data.map((item) => dataSet.add(item.releaseDate));
    return dataSet;
  }, [data]);

  const options = {
    inline: true,
    showMonths: 2,
    enable: Array.from(eventDates),
    disableMobile: 'true',
    onChange: (_, dStr) => {
      const element = findElementByDate(dStr);
      if (element) {
        navigate(
          findPathPages(PAGE_PATH.GAME).replace(':gameCode', element.gameCode),
        );
      }
    },
    onDayCreate: (dObj, dStr, fp, dayElem) => {
      if (eventDates.has(formatDate(dayElem.dateObj))) {
        const element = findElementByDate(formatDate(dayElem.dateObj));

        dayElem.innerHTML += `<div class="event"><img src="${
          element.icon || ComingSoonImg
        }" alt="${element.name}" />`;
      }
    },
    onMonthChange: (date, str, instance) => {
      handleSetMonth(new Date(instance.currentYear, instance.currentMonth, 1));
    },
    locale: {
      weekdays: weekdaysName,
    },
  };

  useEffect(() => {
    if (!fp?.current?.flatpickr) return;
    fp.current.flatpickr.jumpToDate(new Date(Date.parse(currentDate)));
  }, [currentDate]);

  return (
    <div className="flatpickr-calendar-wrapper calendar-event">
      <div className="flatpickr-calendar-container">
        <Flatpickr options={options} ref={fp} />
      </div>
    </div>
  );
};

Calendar.propTypes = propTypes;
export default Calendar;
