import React, { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Popover as MuiPopover } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import styles from 'components/GameItem/FullInfo/FullInfo.module.scss';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  countMore: PropTypes.number.isRequired,
};

const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          boxShadow: '0px 4px 12px 4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

const Popover = ({ data, countMore }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span className={styles.tagMore} onClick={handleClick}>
        +{data.length - countMore}
      </span>
      <ThemeProvider theme={theme}>
        <MuiPopover
          id={anchorEl ? 'simple-popover' : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={classNames(styles.tag, styles.popover)}>
            {data.map((value, index) => (
              <span key={index}>{value}</span>
            ))}
          </div>
        </MuiPopover>
      </ThemeProvider>
    </>
  );
};

Popover.propTypes = propTypes;
export default Popover;
