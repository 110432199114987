export const config = {
  local: {
    operator_code: 'gt_1124',
    game_code: 'adrenaline_rush_super_boost',
    game_url: 'https://api.gt.evoent.tech/testing-tool/api/v1/launch-game',
    lang: 'EN',
    locale: 'en-US',
    player_currency: 'EUR',
  },
  stage: {
    operator_code: 'gt_1124',
    game_code: 'adrenaline_rush_super_boost',
    game_url: 'https://api.gt.evoent.tech/testing-tool/api/v1/launch-game',
    lang: 'EN',
    locale: 'en-US',
    player_currency: 'EUR',
  },
  prod: {
    operator_code: 'eu_10077',
    game_code: 'adrenaline_rush_super_boost',
    game_url: 'https://api-kube.evoplay.games/testing-tool/api/v1/launch-game',
    lang: 'EN',
    locale: 'en-US',
    player_currency: 'EUR',
  },
};
