import React, { useCallback } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getPackStructure } from 'utils/promotions';

import Checkbox from 'components/UI/Checkbox/Checkbox';

import styles from 'components/DownloadMaterials/Dialog/DownloadDialog/Content/Content.module.scss';

const propTypes = {
  materials: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
    .isRequired,
  row: PropTypes.string.isRequired,
  isCheckedWholeCategory: PropTypes.bool.isRequired,
  isCheckedRow: PropTypes.func.isRequired,
  isCheckedPack: PropTypes.func.isRequired,
  handleClickEl: PropTypes.func.isRequired,
  downloadFilters: PropTypes.shape({}),
};

const Base = ({
  materials,
  downloadFilters,
  row,
  isCheckedWholeCategory,
  isCheckedRow,
  isCheckedPack,
  handleClickEl,
}) => {
  const isCheckedEl = useCallback(
    (row, pack, el) => {
      if (downloadFilters) {
        if (downloadFilters[row]) {
          if (Array.isArray(downloadFilters[row])) {
            return downloadFilters[row].includes(el);
          }

          if (downloadFilters[row][pack]) {
            return downloadFilters[row][pack].includes(el);
          }
        }
      }

      return false;
    },
    [downloadFilters],
  );

  if (Array.isArray(materials)) {
    return (
      <>
        {materials.map((label) => (
          <div className={styles.checkbox} key={`${row}${label}`}>
            <div
              className={classNames(
                styles.checkboxArea,
                styles.checkboxAreaEmpty,
              )}
            >
              <div className={styles.checkboxItem} key={`${row}${label}`}>
                <Checkbox
                  checked={
                    isCheckedWholeCategory ||
                    isCheckedRow(row) ||
                    isCheckedEl(row, '', label)
                  }
                  onClick={() => handleClickEl(row, '', label)}
                />
                <span></span>
              </div>
            </div>
            <div className={styles.checkboxLabel}>{label}</div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {Object.keys(getPackStructure(materials)).map((label) => (
        <div className={styles.checkbox} key={`${row}${label}`}>
          <div className={styles.checkboxArea}>
            {Object.keys(materials).map((el) => {
              if (!materials[el].includes(label)) {
                return null;
              }
              return (
                <div
                  className={styles.checkboxItem}
                  key={`${row}${label}${el}`}
                >
                  <Checkbox
                    checked={
                      isCheckedWholeCategory ||
                      isCheckedRow(row) ||
                      isCheckedPack(row, el) ||
                      isCheckedEl(row, el, label)
                    }
                    onClick={() => handleClickEl(row, el, label)}
                  />
                  <span>{el}</span>
                </div>
              );
            })}
          </div>
          <div className={styles.checkboxLabel}>{label}</div>
        </div>
      ))}
    </>
  );
};

Base.propTypes = propTypes;
export default Base;
