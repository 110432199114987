import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { show } from 'store/slices/alert';
import {
  clearError,
  downloadMaterialsSelector,
} from 'store/slices/downloadMaterials';

import Dialog from 'components/UI/Dialog/Dialog';

import styles from 'components/DownloadMaterials/Dialog/ErrorDialog/ErrorDialog.module.scss';

const ErrorDialog = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [data, setData] = useState({});

  const error = useSelector(downloadMaterialsSelector.getError);

  const handleCloseDlg = useCallback(() => {
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (error && typeof error === 'string') {
      dispatch(show({ type: 'error', text: error }));
      handleCloseDlg();
    } else if (error?.errors?.length && error.folderPath) {
      setData(
        error.errors.reduce((acc, { url }) => {
          const errorPatch = url
            .split(error.folderPath)
            .pop()
            .replace(/\+/g, ' ')
            .split('/');
          let name, file;
          if (errorPatch.length === 1) {
            name = error.name;
            file = errorPatch[0];
          } else {
            name = errorPatch.shift();
            file = errorPatch.join(' / ');
          }
          if (acc[name]) {
            acc[name].push({ url, file });
          } else {
            acc[name] = [{ url, file }];
          }
          return acc;
        }, {}),
      );
    }
  }, [error]);

  useEffect(() => {
    return handleCloseDlg;
  }, [pathname]);

  if (!Object.keys(data).length) {
    return null;
  }

  return (
    <Dialog open handleClose={handleCloseDlg}>
      <div className={styles.header}>
        <i className={styles.icon} />
        <div className={styles.title}>Some files are missing</div>
        <div className={styles.subTitle}>
          The following files aren&apos;t available. Please try to download
          other files.
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <div>Name</div>
          <div>Missing File</div>
          <div>Link</div>
        </div>
        <div className={styles.contentRows}>
          {Object.keys(data).map((item) => (
            <div className={styles.row} key={item}>
              <div className={styles.item}>{item}</div>
              <div>
                {data[item].map(({ url, file }, index) => (
                  <div className={styles.rowItem} key={index}>
                    <div className={styles.file}>
                      <i className={styles.fileIcon} />
                      {file}
                    </div>
                    <div className={styles.label}>{url}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorDialog;
