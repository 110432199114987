import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { findPathPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';

import styles from 'components/pages/Roadmap/Item/Item.module.scss';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  gameCode: PropTypes.string.isRequired,
};

const ItemLayout = ({ children, gameCode }) =>
  gameCode ? (
    <Link
      to={findPathPages(PAGE_PATH.GAME).replace(':gameCode', gameCode)}
      className={classNames(styles.wrapper, styles.wrapperLink)}
    >
      {children}
    </Link>
  ) : (
    <div className={styles.wrapper}>{children}</div>
  );

ItemLayout.propTypes = propTypes;
export default ItemLayout;
