import React from 'react';
import { useParams } from 'react-router-dom';

import CookieNotice from 'components/pages/Contents/items/CookieNotice';
import PrivacyNotice from 'components/pages/Contents/items/PrivacyNotice';
import WebsiteTerms from 'components/pages/Contents/items/WebsiteTerms';
import NotFound from 'components/pages/NotFound/NotFound';

import styles from 'components/pages/Contents/Contents.module.scss';

export const pages = {
  privacy: {
    title: 'Privacy Notice',
    page: <PrivacyNotice />,
  },
  terms: {
    title: 'Website Terms',
    page: <WebsiteTerms />,
  },
  cookie: {
    title: 'Cookie Notice',
    page: <CookieNotice />,
  },
};

const Contents = () => {
  const { page } = useParams();

  const data = pages[page];

  if (!data) {
    return <NotFound />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.content}>{data.page}</div>
    </div>
  );
};

export default Contents;
