import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { show } from 'store/slices/alert';

import { getS3Items } from 'utils/s3';

export const useS3Bucket = ({
  name = 'evoplay-cloud',
  prefixes,
  formatItems,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const items = await getS3Items(name, prefixes);

      error && setError(false);

      return formatItems?.(items, prefixes) ?? items;
    } catch (e) {
      dispatch(show({ type: 'error', text: e.message }));
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [name, prefixes, error, formatItems]);

  return {
    fetchData,
    loading,
    error,
  };
};
