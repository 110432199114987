import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import classNames from 'classnames';

import Preload from 'images/HomePage/Banner/banner.jpg';
import Vid from 'images/HomePage/Banner/banner.webm';

import styles from './Banner.module.scss';

const Banner = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.video, { [styles.load]: isVideoLoaded })}
      >
        <img src={Preload} alt="preload" />
        <ReactPlayer
          url={Vid}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          width="100%"
          height="auto"
          onReady={onLoadedData}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          Welcome to
          <br />
          the Evoplay
          <br />
          <span>Client Area</span>
        </div>
        <div className={styles.description}>
          Access everything you need right at your fingertips: game data,
          promotional materials, technical documentation, and more!
        </div>
        <div className={styles.bottomTitle}>
          Let&#39;s shape the future of <span>iGaming!</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
