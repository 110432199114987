import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalOpened: false,
  items: {},
  zipGenerating: false,
};

const downloadProcessSlice = createSlice({
  name: 'downloadProcess',
  initialState,
  reducers: {
    setModalOpened: (state, { payload }) => {
      state.modalOpened = payload;
    },
    process: (state, action) => {
      const { name, percent } = action.payload;
      state.items[name] = {
        percent,
      };
    },
    finish: (state, action) => {
      if (state.items[action.payload]) {
        delete state.items[action.payload];
      }
    },
    zipGenerationStart: (state) => {
      state.zipGenerating = true;
    },
    zipGenerationEnd: (state) => {
      state.zipGenerating = false;
    },
    clear: () => initialState,
  },
});

export const downloadProcessSelector = {
  getModalOpened: ({ downloadProcess }) => downloadProcess.modalOpened,
  getItems: ({ downloadProcess }) => downloadProcess.items,
  getZipGenerating: ({ downloadProcess }) => downloadProcess.zipGenerating,
};

export const {
  setModalOpened,
  process,
  finish,
  zipGenerationStart,
  zipGenerationEnd,
  clear,
} = downloadProcessSlice.actions;
export default downloadProcessSlice.reducer;
