import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { useGetBetCurrencyQuery } from 'store/api/gameService/recipe.api';

import Loader from 'components/Loader/Loader';
import Select from 'components/UI/Select/Select';

import { CRYPTO, CUSTOM, WORD } from 'components/config/currency';

import { ReactComponent as CurrenciesIcon } from 'images/Icons/Currencies.svg';

import styles from 'components/pages/Game/Game.module.scss';

const propTypes = {
  gameCode: PropTypes.string.isRequired,
};

const Currency = ({ gameCode }) => {
  const [currency, setCurrency] = useState('USD');
  const { data, isFetching, isError } = useGetBetCurrencyQuery({
    currency,
    gameCode,
  });
  const defaultText = <span className={styles.empty}>&mdash;</span>;

  return (
    <>
      <div className={styles.currencies}>
        <CurrenciesIcon />
        <div className={styles.title}>Bets in Currencies</div>
        <Select
          handleSelect={(_, value) => {
            setCurrency(value);
          }}
          title="Currency"
          label="currency"
          items={{ ...WORD, ...CUSTOM, ...CRYPTO }}
          value={currency}
          search
          widthDropdown={300}
        />
      </div>
      <div className={styles.column}>
        {isFetching && <Loader />}
        {!isFetching && data && (
          <>
            <div className={styles.row}>
              <span>Min Bet:</span>
              {data.minBet && !isError ? (
                <span className={styles.bet}>{data.minBet}</span>
              ) : (
                defaultText
              )}
            </div>
            <div className={styles.row}>
              <span>Default Bet:</span>
              {data.defaultBet && !isError ? (
                <span className={styles.bet}>{data.defaultBet}</span>
              ) : (
                defaultText
              )}
            </div>
            <div className={styles.row}>
              <span>Max Bet:</span>
              {data.maxBet && !isError ? (
                <span className={styles.bet}>{data.maxBet}</span>
              ) : (
                defaultText
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

Currency.propTypes = propTypes;
export default Currency;
