import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'components/Slider/Thumbnail/Thumbnail.module.scss';

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
};
const Thumbnail = ({ item, isActive, handleClick, type }) => (
  <div
    className={classNames(styles.wrapper, styles[type], {
      [styles.active]: isActive,
    })}
    onClick={() => handleClick(item)}
  >
    <img src={item.icon} alt="thumbnails img" />
    {type === 'vertical' && (
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: item.name }}
      />
    )}
    {isActive && (
      <div className={styles.progress}>
        <div className={styles.processActive} />
        <div className={styles.processProgress} />
      </div>
    )}
  </div>
);
Thumbnail.propTypes = propTypes;
export default Thumbnail;
