export const mapUserInfo = (userInfo) => ({
  'E-mail': userInfo.email,
  ...(userInfo.firstName && {
    'First name': userInfo.firstName,
  }),
  ...(userInfo.lastName && {
    'Last name': userInfo.lastName,
  }),
  ...(Object.keys(userInfo?.attributes || []).length && {
    ...Object.keys(userInfo.attributes).reduce(
      (acc, attr) => ({ ...acc, [attr]: userInfo.attributes[attr].join() }),
      {},
    ),
  }),
});
