import { documentationPath } from 'components/config/cloud';

export const data = {
  Integration: {
    English: [
      {
        title: 'SeamlessWallet API documentation - Europe',
        link: `${documentationPath}/SeamlessWallet_Europe_API_Documentation.pdf`,
      },
      {
        title: 'SeamlessWallet API documentation - MGA',
        link: `${documentationPath}/SeamlessWallet_MGA_API_Documentation.pdf`,
      },
      {
        title: 'Seamless Wallet Free Spins granting guide',
        link: `${documentationPath}/SeamlessWallet+free+spins+granting+guide.pdf`,
      },
    ],
  },
  'Back Office': {
    switch: ['English', 'Chinese'],
    English: [
      {
        title: 'Back-office manual documentation',
        link: `${documentationPath}/BackOffice_Manual_Documentation.pdf`,
      },
      {
        title: 'Back-office manual documentation 2.0',
        link: `${documentationPath}/BackOffice_Manual_Documentationv+2.0.pdf`,
      },
    ],
    Chinese: [
      {
        title: 'Back-office manual documentation',
        link: `${documentationPath}/ZH_BackOffice_Manual_Documentation.pdf`,
      },
      {
        title: 'Back-office manual documentation 2.0',
        link: `${documentationPath}/ZH_BackOffice_Manual_Documentation+2.0.pdf`,
      },
    ],
  },
  'Supported Currencies': {
    English: [
      {
        title: 'Supported Currencies documentation',
        link: `${documentationPath}/Supported_Currencies.xlsx`,
      },
    ],
  },
};
