/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import ContentsTable from 'components/pages/Contents/ContentsTable';

import styles from 'components/pages/Contents/Contents.module.scss';

const CookieNotice = () => (
  <div>
    <p>The Website uses cookies.</p>
    <p>
      A cookie is a small file of letters and numbers that we place on your
      electronic device. We use these cookies for various purposes:
    </p>
    <ul>
      <li>
        Security cookies are used in order to protect the Website from fraud,
        abuse, and cyberattacks;
      </li>
      <li>
        Essential cookies are essential for the functioning of the Website:
        without these cookies the Website will not work;
      </li>
      <li>
        Functionality cookies are used to provide you with the non-essential
        functions of the Website; if these cookies are disabled, some functions
        of the Website will not be available;
      </li>
      <li>
        Analytics cookies are used to distinguish you from other visitors of the
        Website. We use this information to create statistics to improve the
        quality of our Website.
      </li>
    </ul>
    <h3 className={styles.header}>Cookies which are used by the Website.</h3>
    <ContentsTable
      columns={[
        { title: 'Name of the cookie', span: '1 / 3' },
        { title: 'Type' },
        { title: 'Expiration time' },
        { title: 'Purpose of use', span: '6 / 10' },
      ]}
      rows={[
        [
          'KEYCLOAK_IDENTITY',
          <div className={styles.center} key="1">
            Essential cookie
          </div>,
          <div className={styles.center} key="1">
            1 month
          </div>,
          'This cookie lives with your browser session and can also be refreshed with SSO',
        ],
        [
          'KEYCLOAK_SESSION',
          <div className={styles.center} key="1">
            Essential cookie
          </div>,
          <div className={styles.center} key="2">
            1 month
          </div>,
          'Your session ID is assigned to the current session',
        ],
        [
          'Oauth_token_request_state',
          <div className={styles.center} key="1">
            Security cookie
          </div>,
          <div className={styles.center} key="3">
            1 month
          </div>,
          'It is a part of the OAuth specification designed to prevent hacking of the redirect link after login',
        ],
        [
          '_hjSessionUser_3400411',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            1 year
          </div>,
          'Ensures data from subsequent visits to the same site are attributed to the same user ID',
        ],
        [
          '_hjHasCachedUserAttributes',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Enables us to know whether the data set in _hjUserAttributes Local Storage item is up to date or not',
        ],
        [
          '_hjUserAttributesHash',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            2 minutes
          </div>,
          'Enables us to know when any User Attribute has changed and needs to be updated',
        ],
        [
          '_hjUserAttributes',
          <div className={styles.center} key="1">
            Local storage item
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Stores User Attributes sent through the Hotjar Identify API',
        ],
        [
          'hjViewportId',
          <div className={styles.center} key="1">
            Session storage item
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Stores user viewport details such as size and dimensions',
        ],
        [
          'hjActiveViewportIds',
          <div className={styles.center} key="1">
            Local storage item
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Stores user active viewports IDs',
        ],
        [
          '_hjSession_3400411',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            30 minutes
          </div>,
          'Ensures subsequent requests in the session window are attributed to the same session',
        ],
        [
          '_hjCookieTest',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Checks to see if the Hotjar Tracking Code can use cookies. If it can, a value of 1 is set',
        ],
        [
          '_hjLocalStorageTest',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Checks if the Hotjar Tracking Code can use Local Storage. If it can, a value of 1 is set',
        ],
        [
          '_hjSessionStorageTest',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Checks if the Hotjar Tracking Code can use Session Storage. If it can, a value of 1 is set',
        ],
        [
          '_hjTLDTest',
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            Session duration
          </div>,
          'Enables us to try to determine the most generic cookie path to use, instead of page hostname',
        ],
        [
          {
            rowClass: styles.rows,
            element: (
              <React.Fragment key="0">
                <div>_ga</div>
                <div>_ga_FK9NRWTR92</div>
              </React.Fragment>
            ),
          },
          <div className={styles.center} key="1">
            Analytics cookie
          </div>,
          <div className={styles.center} key="3">
            2 years
          </div>,
          'These cookies are used to identify unique users and track their activities on various websites that use Google Analytics',
        ],
        [
          'cookies_settings',
          <div className={styles.center} key="1">
            Essential cookie
          </div>,
          <div className={styles.center} key="3">
            1 month
          </div>,
          'This cookie remembers your cookie preferences on the Website',
        ],
      ]}
    />
  </div>
);

export default CookieNotice;
