export const WORD = {
  AED: 'United Arab Emirates Dirham',
  AFN: 'Afghan Afghani',
  ALL: 'Albanian Lek',
  AMD: 'Armenian Dram',
  ANG: 'Netherlands Antillean Guilder',
  AOA: 'Angolan Kwanza',
  ARS: 'Argentine Peso',
  AUD: 'Australian Dollar',
  AZN: 'Azerbaijani Manat',
  BAM: 'Bosnia and Herzegovina convertible mark',
  BND: 'Bruneian Dollar',
  BDT: 'Bangladeshi Taka',
  BGN: 'Bulgarian Lev',
  BOB: 'Bolivian Boliviano',
  BRL: 'Brazilian Real',
  BTN: 'Bhutanese Ngultrum',
  BWP: 'Botswanan Pula',
  BYN: 'Belarusian Ruble',
  BZD: 'Belize dollar',
  CAD: 'Canadian Dollar',
  CDF: 'Congolese Franc',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Chinese Yuan',
  COP: 'Colombian Peso',
  CVE: 'Cape Verdean escudo',
  CRC: 'Costa Rican Colón',
  CZK: 'Czech Republic Koruna',
  DJF: 'Djiboutian Franc',
  DKK: 'Danish Krone',
  DZD: 'Algerian Dinar',
  DOP: 'Dominican Peso',
  EGP: 'Egyptian Pound',
  ETB: 'Ethiopian birr',
  EUR: 'Euro',
  GBP: 'British Pound Sterling',
  GEL: 'Georgian Lari',
  GHS: 'Ghanaian Cedi',
  GMD: 'Gambian Dalasi',
  GNF: 'Guinean franc',
  GTQ: 'Guatemalan Quetzal',
  GYD: 'Guyanese Dollar',
  HKD: 'Hong Kong Dollar',
  HNL: 'Honduran Lempira',
  HRK: 'Croatian Kuna',
  HTG: 'Haitian gourde',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli New Sheqel',
  INR: 'Indian Rupee',
  IQD: 'Iraqi Dinar',
  IRR: 'Iranian Rial',
  ISK: 'Icelandic Króna',
  JMD: 'Jamaican Dollar',
  JOD: 'Jordanian Dinar',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KGS: 'Kyrgystani Som',
  KHR: 'Cambodian Riel',
  KMF: 'Comorian Franc',
  KRW: 'South Korean Won',
  KWD: 'Kuwaiti Dinar',
  KZT: 'Kazakhstani Tenge',
  LAK: 'Lao Kip',
  LBP: 'Lebanese Pound',
  LKR: 'Sri Lankan Rupee',
  LRD: 'Liberian Dollar',
  LYD: 'Libyan Dinar',
  MAD: 'Moroccan Dirham',
  MDL: 'Moldovan Leu',
  MKD: 'Macedonian Denar',
  MMK: 'Myanma Kyat',
  MNT: 'Mongolian Tugrik',
  MVR: 'Maldivian rufiyaa',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  MZN: 'Mozambican Metical',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NIO: 'Nicaraguan Córdoba',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese rupee',
  NZD: 'New Zealand Dollar',
  OMR: 'Omani Rial',
  PEN: 'Peruvian Nuevo Sol',
  PHP: 'Philippine Peso',
  PKR: 'Pakistani Rupee',
  PLN: 'Polish Zloty',
  PYG: 'Paraguayan Guarani',
  QAR: 'Qatari Rial',
  RON: 'Romanian Leu',
  RSD: 'Serbian Dinar',
  RWF: 'Rwandan franc',
  RUB: 'Russian Ruble',
  SAR: 'Saudi Riyal',
  SCR: 'Seychellois Rupee',
  SDG: 'Sudanese Pound',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  SLL: 'Sierra Leonean Leone',
  SSP: 'South Sudanese Pound',
  SRD: 'Surinamese Dollar',
  THB: 'Thai Baht',
  TJS: 'Tajikistani Somoni',
  TMT: 'Turkmenistani Manat',
  TND: 'Tunisian Dinar',
  TRY: 'Turkish Lira',
  TTD: 'Trinidad and Tobago Dollar',
  TWD: 'New Taiwan Dollar',
  TZS: 'Tanzanian Shilling',
  UAH: 'Ukrainian Hryvnia',
  UGX: 'Ugandan Shilling',
  USD: 'United States Dollar',
  UYU: 'Uruguayan Peso',
  UZS: 'Uzbekistan Som',
  VES: 'Venezuelan Bolívar',
  VND: 'Vietnamese Dong',
  XAF: 'CFA Franc BEAC',
  XCD: 'East Caribbean Dollar',
  XOF: 'CFA Franc BCEAO',
  ZAR: 'South African Rand',
  ZMW: 'Zambian Kwacha',
  ZWL: 'Zimbabwean Dollar',
};

export const CUSTOM = {
  CTWD: '1 CTWD = 0.01 TWD',
  KIDR: '1 KIDR = 1000 IDR',
  KVND: '1 KVND = 1000 VND',
  KLAK: '1 KLAK = 1000 LAK',
  KMMK: '1 KMMK = 1000 MMK',
  IRT: 'Iranian Toman = IRR/10',
  CUSD: '1 CUSD = 0.01 USD',
  SLE: '1 SLE = 1000 SLL',
  ARX: 'Blue Dollar',
};

export const CRYPTO = {
  DASH: 'Digital Cash',
  BUSD: 'Binance USD',
  mDash: 'mili dash=0.001 DASH',
  USDT: 'Tether',
  MBC: 'milli bitcoin = 0.001 BTC',
  UBTC: 'micro bitcoin = 0.000001 BTC',
  BSV: 'Bitcoin SV',
  MLTC: 'milli litecoin = 0.001 LTC',
  METH: 'milli ethereum = 0.001 ETH',
  MBCH: 'milli bitcoincash = 0.001 BCH',
  DOGE: 'Dogecoin',
  XRP: 'Ripple',
  MELLO: 'Mello Token',
  MBNB: 'mili binance coin = 0.001 BNB',
  XMR: 'Monero',
  CHP: 'CoinPoker',
  NEO: 'Neo',
  ACA: 'ACA Token',
  TRX: 'TRON',
  TFUN: 'FUNToken',
  USDC: 'USD Coin',
  TON: 'Toncoin',
  SHIB: 'Shiba Inu',
  FTN: 'Fasttoken',
};
