import { useCallback } from 'react';
import ReactPlayer from 'react-player';

import { useWatchDepthTracking } from 'hooks/watchDepthTracking';
import PropTypes from 'prop-types';

import { triggerPlausibleEvent } from 'utils/plausible';

const WatchDepthPlayer = ({ event, onStart, onPlay, onPause, ...props }) => {
  const { startTrackingTime, stopTrackingTime } = useWatchDepthTracking(event);

  const handleStart = useCallback(
    (e) => {
      triggerPlausibleEvent(event);
      onStart?.(e);
    },
    [event, onStart],
  );

  const handlePlay = useCallback(
    (e) => {
      startTrackingTime();
      onPlay?.(e);
    },
    [startTrackingTime, onPlay],
  );

  const handlePause = useCallback(
    (e) => {
      stopTrackingTime();
      onPause?.(e);
    },
    [stopTrackingTime, onPause],
  );

  return (
    <ReactPlayer
      onStart={handleStart}
      onPlay={handlePlay}
      onPause={handlePause}
      {...props}
    />
  );
};

WatchDepthPlayer.propTypes = {
  event: PropTypes.exact({
    name: PropTypes.string,
    props: PropTypes.object,
  }).isRequired,
  onStart: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
};

export default WatchDepthPlayer;
