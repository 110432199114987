import { createSlice } from '@reduxjs/toolkit';

import { EMAILS } from 'config/organization';

const initialState = {
  data: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const userSelector = {
  getInfo: (state) => state.user.data,
  isCustomer: (state) =>
    [EMAILS.tech, EMAILS.games].every(
      (email) => state.user.data?.email?.indexOf(email) === -1,
    ),
};

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
